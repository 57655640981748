import React, {ReactNode, useState} from 'react';
import {Button, Badge, Tooltip, Portal, Paper} from '../index';
import {IconThreeDots} from '../../icon';
import {Absolute, Box} from '../../layout';
import {
    useFloating,
    useInteractions,
    useClick,
    useDismiss,
    autoUpdate,
    offset,
    shift,
    flip
} from '@floating-ui/react';

import styled from 'styled-components';
import MenuItem from './MenuItem';

type Props = {
    align?: 'left' | 'right';
    children: (onClose: () => void) => React.ReactNode;
    disabled?: boolean;
    icon?: ReactNode | ((data: {isOpen: boolean}) => React.ReactNode);
    count?: number;
    loading?: boolean;
    controlTooltip?: string;
    thin?: boolean;
    secondary?: boolean;
    floaterStyles?: React.CSSProperties;
};

export default function MoreMenu(props: Props) {
    const {
        align = 'right',
        children,
        count = 0,
        icon = <IconThreeDots />,
        disabled,
        loading,
        thin = true,
        secondary = false,
        floaterStyles
    } = props;

    const [open, setOpen] = useState(false);
    const {refs, floatingStyles, context} = useFloating({
        placement: align === 'right' ? 'bottom-end' : 'bottom-start',
        open,
        onOpenChange: setOpen,
        whileElementsMounted: autoUpdate,

        // order of middleware functions is important
        middleware: [
            offset(4),
            // keep floater in view if a horizontal scroll would make it offscreen
            shift(),
            // change floater placement to top of reference node if it would be offscreen when scrolling
            flip()
        ]
    });

    const click = useClick(context, {enabled: !disabled});
    const dismiss = useDismiss(context);

    const {getFloatingProps, getReferenceProps} = useInteractions([click, dismiss]);

    return (
        <>
            <Box ref={refs.setReference} position="relative" {...getReferenceProps()}>
                <Tooltip content={props.controlTooltip}>
                    {count > 0 && (
                        <Absolute top="-.5rem" right="-.5rem">
                            <Badge color="red">{count}</Badge>
                        </Absolute>
                    )}
                    <Button
                        aria-label={props.controlTooltip}
                        disabled={disabled}
                        thin={thin}
                        tertiary={!secondary}
                        secondary={secondary}
                        loading={loading}
                        icon={typeof icon === 'function' ? icon({isOpen: open}) : icon}
                    />
                </Tooltip>
            </Box>
            {open && (
                <Portal>
                    <DropdownWrapper
                        ref={refs.setFloating}
                        style={{
                            ...floaterStyles,
                            ...floatingStyles
                        }}
                        {...getFloatingProps()}
                    >
                        <Paper outlined round>
                            {children(() => setOpen(false))}
                        </Paper>
                    </DropdownWrapper>
                </Portal>
            )}
        </>
    );
}

const DropdownWrapper = styled.div`
    width: max-content;

    ${MenuItem} {
        &:first-of-type {
            border-top-left-radius: inherit;
            border-top-right-radius: inherit;
        }

        &:last-of-type {
            border-bottom-left-radius: inherit;
            border-bottom-right-radius: inherit;
        }
    }
`;
