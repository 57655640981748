import {Loader, useAnalytics, useLocalStorage, Viewer} from 'bigdatr-style';
import {SupportedCountry} from 'bigdatr-style/src/team/Team';
import {createContext, useContext, useEffect, useState, useMemo} from 'react';
import React from 'react';
import api from '~/app/EntityApi';
import {useLocation} from 'react-router-dom';
import getSafeCountry from './getSafeCountry';
import {useRoutes} from '~/app/Router';

type CountryContextValue = {
    country: SupportedCountry;
    setCountry: (next: SupportedCountry) => void;
    isCountryOnTrial: boolean;
    isMediaValueOnTrial: boolean;
    isCreativeOnTrial: boolean;
};
const CountryContext = createContext<CountryContextValue | undefined>(undefined);

export function CountryProvider(props: {children: React.ReactNode; viewer: Viewer}) {
    const {viewer} = props;
    const updateMessage = api.user.userUpdateSessionMetadata.useRequest();
    const routes = useRoutes();
    const analytics = useAnalytics();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [hasCountryInUrl, setHasCountryInUrl] = useState<boolean>(searchParams.has('country'));

    const [countryLocalStorage, setCountryLocalStorage] =
        useLocalStorage<SupportedCountry>('bd-app-lastCountry');

    const setCountry = (next: SupportedCountry) => {
        updateMessage.request({
            user: {
                id: viewer.user.id,
                sessionMetadata: {country: next}
            }
        });
        setCountryLocalStorage(next);

        // go to home page on country switch
        routes.exploreCreative.push({});
    };

    const {hasAccess, country: lastSafeCountry} = getSafeCountry(viewer);

    // handles redirects if the user has no access to the app
    useEffect(() => {
        // don't force any redirects if user has access
        if (hasAccess) return;

        const noAccessAllowedRoutes = [
            routes.trialExpired.to(),
            routes.noPermissions.to(),
            '/creative'
        ];

        const isOnNoAccessRoute = noAccessAllowedRoutes.some((path) =>
            location.pathname.startsWith(path)
        );

        // dont force any redirects if the user is already on a route that no access users are
        // allowed to see
        if (isOnNoAccessRoute) return;

        const {currentTeam} = viewer;
        if (currentTeam.hasNoPermissions) routes.noPermissions.replace();
        if (currentTeam.isAdminOnlyTeam && currentTeam.hasTeamAdmin)
            routes.userAdminTeamList.replace({});
        if (currentTeam.trialExpired) routes.trialExpired.replace();
    }, [hasAccess, location.pathname, viewer.currentTeamId]);

    // this will update local storage with whatever the API says is true. this is needed in case
    // local storage value says NZ, but viewer data returns AU. use cases of this being important is
    // if customers stopped paying for NZ, but in their local storage NZ was the last used country.
    // when this happens we should update the local storage value to AU
    useEffect(() => {
        if (hasAccess && lastSafeCountry !== countryLocalStorage) {
            setCountryLocalStorage(lastSafeCountry);
        }
    }, [lastSafeCountry]);

    // for logging purposes
    useEffect(() => {
        if (updateMessage.isError) {
            analytics.trackError(updateMessage.requestError);
        }
    }, [updateMessage.isError]);

    // force country selection based on URL
    useEffect(() => {
        if (!hasCountryInUrl) {
            return;
        }

        const countryFromUrl = searchParams.get('country') as SupportedCountry;

        if (!viewer.currentTeam.countriesWithAccess.includes(countryFromUrl)) {
            setHasCountryInUrl(false);
            return;
        }

        switch (countryFromUrl) {
            case SupportedCountry.AU:
            case SupportedCountry.NZ:
                // update local storage and user session
                setCountry(countryFromUrl);
                setHasCountryInUrl(false);
                break;
            default:
                setHasCountryInUrl(false);
                break;
        }
    }, []);

    const country = countryLocalStorage ?? lastSafeCountry;
    const isCountryOnTrial = viewer.isCountryOnTrial(country);

    const isMediaValueOnTrial = viewer.isProductOnTrial('MediaValue', country);
    const isCreativeOnTrial = viewer.isProductOnTrial('AdvertisingCreative', country);

    const data: CountryContextValue = useMemo(() => {
        return {
            country,
            setCountry,
            isCountryOnTrial,
            isMediaValueOnTrial,
            isCreativeOnTrial
        };
    }, [country, isCountryOnTrial, isMediaValueOnTrial, isCreativeOnTrial]);

    return (
        <CountryContext.Provider value={data}>
            {hasCountryInUrl ? <Loader /> : props.children}
        </CountryContext.Provider>
    );
}

export function useCountry() {
    const context = useContext(CountryContext);
    if (!context) throw new Error('CountryContext is not yet initialized');
    return context;
}

/** Should only be used in places where country context might not be initialised, eg on public creative routes */
export function useCountryUnsafe() {
    const context = useContext(CountryContext);
    return context;
}
