import * as SegmentApi from './segment';
import * as ViewerApi from './viewer';
import * as FilterApi from './filter';
import * as SearchApi from './search';
import * as CreativeApi from './creative';
import * as TeamApi from './team';

const rqApi = {
    ...CreativeApi,
    ...FilterApi,
    ...SearchApi,
    ...SegmentApi,
    ...TeamApi,
    ...ViewerApi
};

export default rqApi;
