import React from 'react';
import {sortBy} from 'bigdatr-style';
import NzComingSoon from './articles/NzComingSoon';
import DataExportLaunch from './articles/DataExportLaunch';
import AdCompliance from './articles/AdCompliance';
import NzArrived from './articles/NzArrived';
import UiImprovements from './articles/UiImprovements';

export default function useNewsItems() {
    const newsItems = sortBy(
        [
            {
                date: new Date('2025-03-27'),
                title: 'UI Improvements',
                content: <UiImprovements />
            },
            {
                date: new Date('2024-10-14'),
                title: 'NZ Digital Creative - Now Available',
                content: <NzArrived />
            },
            {
                date: new Date('2024-08-15'),
                title: 'Mitigate Potential Risks with Advertising Compliance',
                content: <AdCompliance />
            },
            {
                date: new Date('2024-08-07'),
                title: 'New Update — Track Investment Behaviour and Media Growth',
                content: <DataExportLaunch />
            },
            {
                date: new Date('2024-06-25'),
                title: 'NZ Digital Creative - Coming Soon',
                content: <NzComingSoon />
            }
        ],
        (i) => i.date.getTime(),
        true
    );

    return newsItems;
}
