import {
    ClassSchema,
    CreativeStatus,
    GraphqlRequest,
    InfinitePaginationSchema,
    Schemas
} from 'bigdatr-style';
import {
    ConvertNameFiltersToIdsQuery,
    ConvertNameFiltersToIdsQueryVariables,
    ConvertNamesToEntitiesQuery,
    ConvertNamesToEntitiesQueryVariables,
    FiltersV2QueryVariables
} from '~/graphql-enty/graphql';
import {EntityStrict, Meta} from '~/app/Endpoints';
import {ColumnFilterV2} from '~/report/data/ReportItemDefinitionTypes';
import {AdType, Brand, Campaign, Category, Industry, MediaType, Product} from 'bigdatr-style';
import {Publication, Region, Publisher, MediaOwner} from 'bigdatr-style';
import {ArraySchema, EntitySchema, ObjectSchema} from 'react-enty';
import Changeset from '~/feature/Changeset';
import Segment from '~/segment/data/Segment';
import {segment} from '~/segment/data/SegmentApi';
import Location from '~/feature/Location';
import PlacementType from '~/feature/PlacementType';
import AdvertiserName from '~/feature/AdvertiserName';
import AdvertiserDomain from '~/feature/AdvertiserDomain';

const convertNameFiltersToIdsQuery = EntityStrict(
    () => import('./ConvertNameFiltersToIdsQuery.graphql')
);
const filtersV2Query = EntityStrict(() => import('./FiltersV2Query.graphql'));
const convertNamesToEntities = EntityStrict(() => import('./ConvertNamesToEntities.graphql'));

type FilterPayload = {
    input: FiltersV2QueryVariables['input'];
    filter: ColumnFilterV2;
};

type FilterResponse = {
    id: string;
    column: string;
    pageInfo: {
        currentPage: number;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
    };
    items: FilterEntities & {
        location: Location[];
        status: CreativeStatus[];
        placementType: PlacementType[];
    };
};

export type FilterApiType = {
    filter: {
        convertNameFiltersToIds: GraphqlRequest<
            ConvertNameFiltersToIdsQuery,
            ConvertNameFiltersToIdsQueryVariables
        >;
        convertNamesToEntities: GraphqlRequest<
            ConvertNamesToEntitiesQuery,
            ConvertNamesToEntitiesQueryVariables
        >;
        filters: GraphqlRequest<{filterV2: {filters: FilterResponse}}, FilterPayload>;
    };
};

const changesetSchema = new EntitySchema('changeset', {});
const locationSchema = new EntitySchema('location', {});
const statusSchema = new EntitySchema('status', {});
const placementType = new EntitySchema('placementType', {});
const advertiserName = new EntitySchema('advertiserName', {});
const advertiserDomain = new EntitySchema('advertiserDomain', {});

changesetSchema.shape = new ClassSchema(Changeset, {});
locationSchema.shape = new ClassSchema(Location, {});
statusSchema.shape = new ClassSchema(CreativeStatus, {});
placementType.shape = new ClassSchema(PlacementType, {});
advertiserName.shape = new ClassSchema(AdvertiserName, {});
advertiserDomain.shape = new ClassSchema(AdvertiserDomain, {});

const schema = {
    adType: Schemas.adTypeList,
    advertiserName: new ArraySchema(advertiserName),
    advertiserDomain: new ArraySchema(advertiserDomain),
    brand: Schemas.brandList,
    campaign: Schemas.campaignList,
    category: Schemas.categoryList,
    changeset: new ArraySchema(changesetSchema),
    industry: Schemas.industryList,
    location: new ArraySchema(locationSchema),
    mediaOwner: Schemas.mediaOwnerList,
    mediaType: Schemas.mediaTypeList,
    placementType: new ArraySchema(placementType),
    product: Schemas.productList,
    publication: Schemas.publicationList,
    publisher: Schemas.publisherList,
    region: Schemas.regionList,
    segment: new ArraySchema(segment),
    status: new ArraySchema(statusSchema)
};

const filtersPaginationSchema = new InfinitePaginationSchema<FilterResponse>('filters', {
    id: (x) => x.id,
    shape: new ObjectSchema({
        items: new ObjectSchema(schema)
    }),
    pageNumber: (x) => x.pageInfo.currentPage,
    flatten: (list, mostRecent) => {
        return {
            ...mostRecent,
            items: list.reduce((rr, page) => {
                const {column, items} = page;
                rr[column] ||= [];
                rr[column] = rr[column].concat(items[column]);
                return rr;
            }, {} as FilterResponse['items'])
        };
    }
});

export const FilterSchema = {
    filterV2: new ObjectSchema({
        filters: filtersPaginationSchema,
        convertNamesToEntities: new ObjectSchema({
            adType: schema.adType,
            brand: schema.brand,
            industry: schema.industry,
            mediaType: schema.mediaType,
            region: schema.region
        })
    })
};

export const FilterApi = {
    filter: {
        convertNameFiltersToIds: convertNameFiltersToIdsQuery,
        filters: async (v: FiltersV2QueryVariables, meta: Meta) => {
            const response = await filtersV2Query(v, meta);
            response.filterV2.filters.id = JSON.stringify([
                v.filter,
                v.input.column,
                v.input.pageSize,
                v.input.searchQuery
            ]);

            return response;
        },

        convertNamesToEntities
    }
};

export type FilterEntities = {
    adType: AdType[];
    brand: Brand[];
    campaign: Campaign[];
    category: Category[];
    industry: Industry[];
    mediaOwner: MediaOwner[];
    mediaType: MediaType[];
    product: Product[];
    publication: Publication[];
    publisher: Publisher[];
    region: Region[];
    changeset: Changeset[];
    segment: Segment[];
};
