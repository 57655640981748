import {Button, Link, Text, useAnalytics, useModal, useViewer} from 'bigdatr-style';
import {Flex} from 'bigdatr-style/layout';
import React from 'react';
import Api from '~/app/EntityApi';
import formatViewerDataToHubspotData from '../../formatViewerDataToHubspotData';

export default function AdCompliance() {
    const analytics = useAnalytics();
    const modal = useModal();
    const submitTrackingForm = Api.tracking.submitHubspotForm.useRequest();
    const viewer = useViewer();

    return (
        <>
            <p>
                Managing advertising compliance can be overwhelming, but Bigdatr’s new solution uses
                your existing creative access to make your compliance processes simpler and more
                efficient.
            </p>
            <p>
                Advertising Compliance is powered by advanced machine learning models that takes key
                terms you want to monitor, and automatically transcribes the criteria against
                150,000+ new advertising daily. The model provides a comprehensive report containing
                advertising content that need further investigation.
            </p>
            <p>
                This feature not only streamlines compliance processes for both your legal and
                marketing teams, but helps marketers receive counsel quicker and the ability to
                pivot strategies using creative samples from thousands of advertisers.{' '}
            </p>
            <p>
                <Text textStyle="strong">Want the details?</Text> Read the PDF below or speak
                directly with a <Link onClick={() => analytics.chat()}>Compliance Data Expert</Link>{' '}
                today.
            </p>
            <Flex>
                <Button
                    onClick={() => {
                        const data = formatViewerDataToHubspotData(viewer);
                        const formInput = Object.entries(data).map(([fieldName, fieldValue]) => {
                            return {
                                name: fieldName,
                                value:
                                    typeof fieldValue === 'string'
                                        ? fieldValue
                                        : JSON.stringify(fieldValue)
                            };
                        });

                        submitTrackingForm.request({
                            formId: 'a2b54997-36b3-49e0-9a2c-d8c3da0d933d',
                            formInput
                        });

                        modal.custom(() => {
                            const pdfLink = 'https://hubs.ly/Q02Ln_b-0';
                            const height = 'calc(100vh - 4rem)';
                            return (
                                <div style={{height}}>
                                    <object
                                        data={pdfLink}
                                        style={{width: '100%', height}}
                                        type="application/pdf"
                                    >
                                        Please see the PDF{' '}
                                        <Link href={pdfLink} target="_blank">
                                            here
                                        </Link>
                                    </object>
                                </div>
                            );
                        });
                    }}
                >
                    How it works
                </Button>
            </Flex>
        </>
    );
}
