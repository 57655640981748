import {safeLazyImport} from 'bigdatr-style';
import {LazyRoute, Parse} from 'bigdatr-style';
import type {QueryStringFilters} from '~/feature/filter/types';
import {DistributionArgs} from '~/feature/media-value/DistributionPage';
import {MediaValuePageArgs} from '~/pages/explore-media-value/types';
import {ComparisonArgs} from './ComparisonPage';

const ExploreMediaValuePage = safeLazyImport(
    () => import('~/feature/media-value/ExploreMediaValuePage')
);
const Breakdown = safeLazyImport(() => import('~/feature/media-value/ExpenditureReportPage'));
const Comparison = safeLazyImport(() => import('~/feature/media-value/ComparisonPage'));
const Distribution = safeLazyImport(() => import('~/feature/media-value/DistributionPage'));

export default {
    /** @deprecated This route now only serves a legacy purpose of handling redirects in a nice way to the customer. Use one of the more specific media value routes below */
    exploreMediaValue: LazyRoute<MediaValuePageArgs>({
        path: '/explore-media-value',
        parse: {
            filter: Parse.query.JSON((x) => x as QueryStringFilters),
            columns: Parse.query.JSON((x) => (Array.isArray(x) ? x : ['brand'])),
            excludedColumns: Parse.query.JSON((x) => (Array.isArray(x) ? x : [])),
            chartType: Parse.query.string((x) =>
                x ? (x as MediaValuePageArgs['chartType']) : 'Matrix'
            ),
            changesetId: Parse.query.number((x) => x)
        },
        component: ExploreMediaValuePage
    }),
    mediaValueExpenditureReport: LazyRoute<MediaValuePageArgs>({
        path: '/explore-media-value/expenditure-report',
        parse: {
            filter: Parse.query.JSON((x) => x as QueryStringFilters),
            columns: Parse.query.JSON((x) => (Array.isArray(x) ? x : ['brand'])),
            excludedColumns: Parse.query.JSON((x) => (Array.isArray(x) ? x : [])),
            chartType: Parse.query.string((x) =>
                x ? (x as MediaValuePageArgs['chartType']) : 'Matrix'
            ),
            changesetId: Parse.query.number((x) => x)
        },
        component: Breakdown
    }),
    mediaValueComparison: LazyRoute<ComparisonArgs>({
        path: '/explore-media-value/comparison',
        parse: {
            rows: Parse.query.JSON((x) => x as ComparisonArgs['rows']),
            dateRangeFilter: Parse.query.JSON((x) => x as ComparisonArgs['dateRangeFilter'])
        },
        component: Comparison
    }),
    mediaValueDistribution: LazyRoute<DistributionArgs>({
        path: '/explore-media-value/distribution',
        parse: {
            rows: Parse.query.JSON((x) => (x as DistributionArgs['rows']) ?? []),
            date: Parse.query.JSON((x) => x as DistributionArgs['date']),
            breakdownColumn: Parse.query.string((x) => x as DistributionArgs['breakdownColumn'])
        },
        component: Distribution
    })
};
