import {useSafeFeatureFlags} from 'bigdatr-style';

export default function useFeatureFlags() {
    const flags: FeatureFlags = useSafeFeatureFlags();
    return flags;
}

// The base type of LDFlagSet is just {[key: string]: any}
// We'll need to manually update the flags we use here.
// Make sure all new flags are typed to possibly be undefined
type FeatureFlags = {
    //
    newReportItems?: boolean;

    creativeListV2?: boolean;
    creativePreviewV2?: boolean;
    filterLayoutV2?: boolean;
    navigationV2?: boolean;
};
