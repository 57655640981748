import {DropdownList, MenuItem, Text, Viewer} from 'bigdatr-style';
import {Box, FlexColumn, Grid} from 'bigdatr-style/layout';
import React from 'react';
import {Users} from 'react-swm-icon-pack';
import useFeatureFlags from '~/util/useFeatureFlags';
import TeamAvatar from './TeamAvatar';

export default function DropdownSwitchTeams(props: {
    viewer: Viewer;
    changeTeam: (teamId: string) => void;
}) {
    const {viewer, changeTeam} = props;
    const {navigationV2} = useFeatureFlags();

    const teamsToRender = viewer.teamsWithAccess.filter((team) => team.id !== viewer.currentTeamId);

    const loggedInAsInfo = (
        <Box px={2} pb={2} fontSize={1} borderBottom="outline">
            Logged in as {viewer.user.username}
        </Box>
    );

    if (teamsToRender.length === 0) return <Box pt={2}>{loggedInAsInfo}</Box>;

    if (navigationV2) {
        return (
            <FlexColumn pt={2} borderBottom="outline">
                <FlexColumn gap={2}>
                    {loggedInAsInfo}
                    <Text px={2} fontSize={1} textStyle="strong">
                        Switch to another team
                    </Text>
                </FlexColumn>
                {teamsToRender.map((team) => (
                    <MenuItem px={2} key={team.id} onClick={() => changeTeam(team.id)}>
                        <Grid gridTemplateColumns="1rem auto" gap={2} alignItems="center">
                            <Users size="16" />
                            {team.name}
                        </Grid>
                    </MenuItem>
                ))}
            </FlexColumn>
        );
    }

    return (
        <DropdownList>
            {teamsToRender.map((team) => (
                <MenuItem key={team.id} onClick={() => changeTeam(team.id)}>
                    <TeamAvatar team={team} subtitle="Switch to" reverseSubtitle />
                </MenuItem>
            ))}
        </DropdownList>
    );
}
