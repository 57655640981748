import React from 'react';
import styled from 'styled-components';
import {Text} from '../index';
import {bool} from '../core/themes/themeGetters';

const Input = styled.input`
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

const Label = styled.label<{after: boolean; disabled: boolean}>`
    ${(p) => {
        const {after} = p;
        return `
            display: flex;
            align-items: center;
            flex-direction: ${after ? 'row' : 'row-reverse'};
            width: max-content;
            gap: .5rem;
            cursor: pointer;
            font-weight: ${p.theme.fontWeights.bold};

            ${bool('disabled')((p) => {
                return `
                    color: ${p.theme.colors.muted};
                    cursor: not-allowed;
                `;
            })(p)}

            & ${Input}:focus ~ ${Check}::before {
                border-color: ${p.theme.colors.brand};
            }
        `;
    }}
`;

const Check = styled.div<{radio?: boolean; selected: boolean; disabled: boolean}>`
    ${(p) => {
        const {radio, selected, disabled} = p;
        const {colors} = p.theme;
        const ballSize = radio ? 0.875 : 1;

        const inactiveStyle = radio
            ? `
                border: 1px solid transparent;
                color: white;
                font-size: 0.7rem;
                width: ${ballSize}rem;
                height: ${ballSize}rem;
                text-align: center;
                line-height: 1rem;
                padding: 0;
            `
            : `
                display: block;
                width: ${ballSize}rem;
                height: ${ballSize}rem;
                background-color: ${colors.white};
                transform: translateX(.25rem);
                transition: transform .2s;

                @media (prefers-reduced-motion) {
                    transition: none;
                }

            `;

        const activeStyle = `
                ${inactiveStyle}
                ${
                    radio
                        ? `
                            background-color: ${colors.brand};
                            border-color: ${colors.brand};
                        `
                        : `transform: translateX(${ballSize}rem);`
                }

            `;

        return `
            display: inline-flex;
            align-items: center;

            ${
                radio
                    ? `
                        justify-content: center;
                        border: 1px solid ${selected ? colors.brand : colors.background3};
                        border-radius: 100%;
                        padding: 2px;
                    `
                    : `
                        width: ${2.25 * ballSize}rem;
                        height: ${ballSize * 1.4}rem;
                        border-radius: 100vh;
                        position: relative;
                        background-color: ${
                            disabled
                                ? colors.background1
                                : selected
                                ? colors.brand
                                : colors.background2
                        };
                        transition: background-color .2s;

                        @media (prefers-reduced-motion) {
                            transition: none;
                        }
                    `
            }

            &::before {
                content: "\\00a0";
                border-radius: 1rem;
                ${selected ? activeStyle : inactiveStyle}

                ${
                    p.disabled
                        ? `
                            background-color: ${colors.background2};
                            border-color: ${colors.background2};
                            color: ${colors.foreground1};
                        `
                        : ''
                }
            }
        `;
    }}
`;

export default function Checkbox(props: {
    value: boolean | undefined;
    onChange: (value: boolean) => any;
    /** The clickable text sitting next to the checkbox */
    label?: React.ReactNode;
    /** Place the checkbox after the label instead of before */
    after?: boolean;
    /** Disable interaction with the checkbox */
    disabled?: boolean;
    /** Render a radiobutton instead of a checkbox */
    radio?: boolean;
    name?: string;
    testid?: string;

    /** Styled system layout props to apply to the wrapping element. */
    layoutProps?: Record<string, any>;
    wrappingElement?: React.ComponentProps<typeof Label>['as'];
}) {
    const {
        value,
        onChange,
        label = '',
        after = true,
        disabled = false,
        radio = false,
        layoutProps
    } = props;

    return (
        <Label after={after} disabled={disabled} style={layoutProps} as={props.wrappingElement}>
            <Input
                type={radio ? 'radio' : 'checkbox'}
                name={props.name || (typeof label === 'string' ? label : undefined)}
                disabled={disabled}
                onChange={() => onChange(!value)}
                checked={Boolean(value)}
                data-testid={props.testid}
            />
            {label && <Text>{label}</Text>}
            <Check radio={radio} selected={Boolean(value)} disabled={disabled} />
        </Label>
    );
}
