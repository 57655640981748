import {graphql} from '~/graphql-react-query/';

export const Search = graphql(`
    query Search($search: SearchInputV2!) {
        searchV2 {
            search(search: $search) {
                pageInfo {
                    currentPage
                    hasNextPage
                    hasPreviousPage
                }
                items {
                    name
                    type
                    id
                    industries {
                        id
                        name
                        type
                    }
                    hasAccess
                }
            }
        }
    }
`);
