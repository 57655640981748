import React, {Suspense, useEffect, useMemo, memo} from 'react';
import {EntityProvider} from '~/app/EntityApi';
import {
    ErrorBoundary,
    ErrorBoundaryProvider,
    useStageFavicon,
    useViewer,
    Viewer
} from 'bigdatr-style';
import {AnalyticsProvider} from 'bigdatr-style';
import {ViewerProvider} from 'bigdatr-style';
import {safeLazyImport} from 'bigdatr-style';
import {useAuth} from 'bigdatr-style';
import {Loader} from 'bigdatr-style';
import {Theme} from 'bigdatr-style';
import {AuthProvider} from 'bigdatr-style';
import {useHistory} from 'react-router-dom';
import {Analytics} from 'bigdatr-style';
import {Segment} from 'bigdatr-style';
import {Google} from 'bigdatr-style';
import {Sentry} from 'bigdatr-style';
import {Hubspot} from 'bigdatr-style';
import {ModalProvider} from 'bigdatr-style';
import {ChatHeadPlaceholder} from 'bigdatr-style';
import {useLocation} from 'react-router-dom';
import {RoutesProvider} from '~/app/Router';
import {BrowserRouter} from 'bigdatr-style';
import PublicRoutes, {publicRoutePaths} from './PublicRoutes';
import pdfRoutePaths from '~/pdf/data/pdfRoutePaths';
import AppLayout from './AppLayout';
import {useSettings} from './SettingsStore';
import {FeatureFlagsProvider, useLaunchDarklyClient} from 'bigdatr-style';
import {CountryProvider, useCountry} from '~/feature/country/CountryContext';
import shouldOnboardUser from '~/util/shouldOnboardUser';
import {GlobalStateProvider} from './GlobalState';
import ReactQueryProvider from '~/api/ReactQueryProvider';
import rqApi from '~/api';
import {SWMIconProvider} from 'react-swm-icon-pack';

const AuthenticatedRoutes = safeLazyImport(() => import('~/app/AuthenticatedRoutes'));
const OnboardRoutes = safeLazyImport(() => import('~/onboard/OnboardRoutes'));
const NoAccessSwitch = safeLazyImport(() => import('~/no-access-routes/NoAccessSwitch'));

const segment = new Segment(process.env.SEGMENT_WRITE_KEY_ENTERPRISE || '');
const sentry = new Sentry({
    dsn: process.env.SENTRY_DSN ?? '',
    service: 'bigdatr-client-main',
    environment: process.env.STAGE ?? '',
    release: process.env.RELEASE_NAME ?? ''
});
const hubspot = new Hubspot();
const googleAnalytics = new Google(process.env.GA_MEASUREMENT_ID || '');
const analytics = new Analytics({segment, sentry, hubspot, googleAnalytics});

//
// Set up react-router, theme, and auth
export default function GlobalProviders() {
    const [settings] = useSettings();
    useStageFavicon();

    // show full error messages in local and dev. helps with PDF debugging in dev
    const showAllErrorMessages = ['local', 'development'].includes(process.env.STAGE || '');
    return (
        <ErrorBoundaryProvider value={{showAllErrorMessages}}>
            <BrowserRouter>
                <Theme name={settings.theme}>
                    <SWMIconProvider color="currentColor" set="outline">
                        <FeatureFlagsProvider>
                            <AnalyticsProvider value={analytics}>
                                <ErrorBoundary>
                                    <Suspense fallback={null}>
                                        <AuthProvider
                                            publicRoutes={publicRoutePaths}
                                            pdfRoutes={pdfRoutePaths}
                                            defaultRoute="/explore-creative"
                                        >
                                            <RoutesProvider>
                                                <ReactQueryProvider>
                                                    <EntyWithAuth />
                                                </ReactQueryProvider>
                                            </RoutesProvider>
                                        </AuthProvider>
                                    </Suspense>
                                </ErrorBoundary>
                            </AnalyticsProvider>
                        </FeatureFlagsProvider>
                    </SWMIconProvider>
                </Theme>
            </BrowserRouter>
        </ErrorBoundaryProvider>
    );
}

function EntyWithAuth() {
    const authData = useAuth();
    // A lot of our data is tainted by the current team id being sent through headers
    // This trashes enty state if the team changes and avoids any tainted entities
    return (
        <EntityProvider key={authData.teamId} meta={authData}>
            {authData.isPublic ? (
                <ModalProvider>
                    <PublicRoutes />
                </ModalProvider>
            ) : (
                <WithViewer />
            )}
        </EntityProvider>
    );
}

function WithViewer() {
    const auth = useAuth();
    const teamId = auth.teamId ?? 'NO_TEAM';
    const response = rqApi.useViewerRequest(teamId);

    const viewer = useMemo(() => {
        return response.data;
    }, [
        // Memoise on
        // team id to check if user switches teams
        teamId,
        // current team name to see if the first user in team changed the name (through the register onboarding flow)
        response.data?.currentTeam.name,
        // team products to see if the first user in team selected industry groups
        JSON.stringify(response.data?.currentTeam.products),
        // userOnboardingInfo to see if they had setup their email notifications
        JSON.stringify(response.data?.userOnboardingInfo),
        // viewerMessage.isSuccess to check for new data
        response.isSuccess,
        // updatedAt to see if there is new data
        response.data?.currentTeam.updatedAt
    ]);

    // confirm that the requested team ID is a team that has access to the app.
    // if its not, try to switch to an active team
    useEffect(() => {
        if (response.isSuccess && viewer) {
            const {currentTeamId} = response.data;

            const teamIdWithPermissions = Viewer.getTeamIdWithPermissions({
                prefferedTeamId: teamId,
                teams: response.data.teams
            });

            // if currentTeamId does not have permissions, automatically switch the user to a team
            // that has permissions
            if (teamIdWithPermissions && currentTeamId !== teamIdWithPermissions) {
                auth.changeTeam(teamIdWithPermissions);
            }
        }
    }, [response.isSuccess]);

    if (response.isError) throw response.error;
    if (response.isSuccess && viewer) {
        return <WithCountry viewer={viewer} />;
    }
    return <Loader />;
}

function WithCountry(props: {viewer: Viewer}) {
    const {viewer} = props;
    const ldClient = useLaunchDarklyClient();

    // Analytics tracking
    useEffect(() => {
        analytics.add('launchDarkly', ldClient);

        const release = process.env.RELEASE_NAME || '';
        const {currentTeam} = viewer;
        analytics.identify({
            id: viewer.user.id,
            name: viewer.user.name,
            firstname: viewer.user.firstname,
            email: viewer.user.username,
            hasMediaValue: currentTeam?.hasAusMediaValue,
            hasCreative: currentTeam?.hasAusCreative,
            teamName: currentTeam?.name,
            teamId: currentTeam?.id,
            release,
            isStaff: viewer.isStaff,
            createdAt: viewer.user.createdAt
        });
    }, [viewer.currentTeamId]);

    if (ldClient.isAnonymousContext()) return <Loader />;

    return (
        <ViewerProvider value={viewer}>
            <GlobalStateProvider>
                <CountryProvider viewer={viewer}>
                    <AuthenticatedView />
                </CountryProvider>
            </GlobalStateProvider>
        </ViewerProvider>
    );
}

const AuthenticatedView = memo(function AuthenticatedViewMemo() {
    const {changeTeam} = useAuth();
    const history = useHistory<{dontTrack: boolean}>();
    const viewer = useViewer();
    const {country} = useCountry();
    const {pathname} = useLocation();
    const isPdfRoute = !!pathname.match(/^\/pdf/);

    const isDev = process.env.STAGE === 'development' || process.env.STAGE === 'local';

    // Open up chat if the openChat query param is preset
    useEffect(() => {
        if (!isPdfRoute && history.location.search.indexOf('openChat=true') !== -1)
            analytics.openChatOnPageLoad();
    }, []);

    useEffect(() => {
        analytics.page();
        return history.listen((history) => {
            if (history.state?.dontTrack) return;
            analytics.page();
        });
    }, [viewer.id]);

    const shouldOnboard = shouldOnboardUser(viewer, country);
    const showNoAccessRoutes =
        viewer.currentTeam.hasNoPermissions || viewer.currentTeam.trialExpired;
    let routes: React.ReactNode;

    let showNavLinks = false;
    if (showNoAccessRoutes) {
        routes = <NoAccessSwitch />;
    } else if (shouldOnboard) {
        routes = <OnboardRoutes />;
    } else {
        routes = <AuthenticatedRoutes />;
        showNavLinks = true;
    }

    return (
        <ModalProvider>
            <AppLayout hideSidebar={isPdfRoute} showNavLinks={showNavLinks} changeTeam={changeTeam}>
                <Suspense fallback={<Loader fadeInTime="0" />}>{routes}</Suspense>
            </AppLayout>
            {isDev && <ChatHeadPlaceholder />}
        </ModalProvider>
    );
});
