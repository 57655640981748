import styled from 'styled-components';
import {bool} from '../core/themes/themeGetters';
import {Box} from '../../layout';
import useStyleFeatureFlags from '../useStyleFeatureFlags';

type Props = {
    color?: string;
    textColor?: string;
    large?: boolean;
};

const {squareRadius} = useStyleFeatureFlags();
export default styled(Box)<Props>`
    background-color: ${(_) => _.theme.colors[_.color || 'brand']};
    color: ${(_) => (_.textColor ? _.theme.colors[_.textColor] : 'white')};
    border-radius: ${(_) => (squareRadius ? _.theme.borderRadiuses.round : '10rem')};
    padding: 0 0.5rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    height: 1rem;
    line-height: 1rem;
    font-weight: ${(_) => _.theme.fontWeights.bold};
    white-space: nowrap;

    ${bool<Props>('large')(() => {
        return `
            font-size: 0.9rem;
            font-weight: inherit;
            height: 1.5rem;
        `;
    })}
`;
