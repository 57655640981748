import {useCallback, useMemo} from 'react';
import {Brand, Category, Creative, Industry, debounce, useAnalytics} from 'bigdatr-style';
import {
    BreakdownDefinitionUpsert,
    CreativeDateRangeFilter,
    MediaDateRangeFilter
} from '~/report/data/ReportItemDefinitionTypes';
import {useCountryUnsafe} from '~/feature/country/CountryContext';
import {QueryStringFilters} from '~/feature/filter/types';
import {CreativeListOrderBy} from '~/creative/affordance/CreativeListSortBy';
import {CreativeDisplay} from '~/feature/creative/display-toggle';

//
// @intent
// Used to track interactions that cant be determined via page tracking
// must use the schema:
// entity:verb
//
// Good:
//      creative:download, brand:share
//
// Bad:
//      brandProfile:request_creativeItemDownload, brandProfile:change_brand

export default function useEventTracking() {
    const analytics = useAnalytics();

    // country context will not be initialised on public routes, which means we could not know in
    // which country the user is in. the alternative is to add `country` param to each tracking
    // method we have, and pass it in where its consumed
    const countryContext = useCountryUnsafe();
    const country = countryContext?.country;
    const isTrialUser = countryContext?.isCountryOnTrial || false;

    const addBaseTrackingData = () => ({
        ...(country && {country}),
        trialUser: isTrialUser // Add trial user flag
    });

    // we're debouncing search tracking separately, because when customer success is looking at
    // analytics, their reports are hard to follow if the users type really slowly.
    // which means they are seeing events like this:
    // creative:search = c
    // creative:search = ca
    // creative:search = car
    const onCreativeAdContentSearch = useCallback(
        debounce((searchString: string) => {
            analytics.trackEvent('creative:search', {searchString, ...addBaseTrackingData()});
        }, 2000),
        []
    );

    // this is debounced for the same reason as above
    const onFilterSearch = useCallback(
        debounce((searchString: string) => {
            analytics.trackEvent('filter:serach', {...addBaseTrackingData(), searchString});
        }, 2000),
        []
    );

    // this is debounced because people may change their mind quickly on which columns they want to
    // have selected. debouncing it by 7 seconds will give a better insight for anyone wanting to
    // know which columns someone selected as a final result
    const onTableColumnSelect = useCallback(
        debounce((selectedColumns: string[]) => {
            analytics.trackEvent('creativeTableColumn:select', {
                selectedColumns,
                ...addBaseTrackingData()
            });
        }, 7000),
        []
    );

    const tracking = useMemo(
        () => ({
            // PDF
            pdfDownload: (event: {
                type: 'creativeExport' | 'report';
                // report pdf specific
                reportId?: string;
                creativeLimit?: number;
                matrixRowLimit?: number;
                tableColumns?: string[];

                // filters for creativeExport
                creativeDateRange?: CreativeDateRangeFilter;
                searchQuery?: string;
                segment?: string[];
                brand?: string[];
                industry?: string[];
                category?: string[];
                product?: string[];
                adType?: string[];
                mediaType?: string[];
                mediaOwner?: string[];
                publisher?: string[];
                publication?: string[];
                region?: string[];
                status?: string[];
                advertiserName?: string[];
                advertiserDomain?: string[];
            }) => {
                const {creativeDateRange, ...otherEventProps} = event;
                let prettyDateInfo: {
                    dateStart?: string;
                    dateEnd?: string;
                    dateRollingMonths?: number;
                } = {};
                if (creativeDateRange?.absolute) {
                    const {endDate, startDate} = creativeDateRange.absolute;
                    prettyDateInfo = {dateEnd: endDate, dateStart: startDate};
                }
                if (creativeDateRange?.relative) {
                    prettyDateInfo = {dateRollingMonths: creativeDateRange.relative.months};
                }

                analytics.trackEvent('pdf:download', {
                    ...prettyDateInfo,
                    ...otherEventProps,
                    ...addBaseTrackingData()
                });
            },

            // Creative
            creativeShare: (event: Creative) =>
                analytics.trackEvent('creative:share', {
                    ...event.toTracking(),
                    ...addBaseTrackingData()
                }),
            creativeDownload: (event: Creative) =>
                analytics.trackEvent('creative:download', {
                    ...event.toTracking(),
                    ...addBaseTrackingData()
                }),
            creativePlaypause: (event: Creative) =>
                analytics.trackEvent('creative:playpause', {
                    ...event.toTracking(),
                    ...addBaseTrackingData()
                }),
            creativeMute: (event: Creative) =>
                analytics.trackEvent('creative:mute', {
                    ...event.toTracking(),
                    ...addBaseTrackingData()
                }),
            creativeView: (event: Creative) =>
                analytics.trackEvent('creative:view', {
                    ...event.toTracking(),
                    ...addBaseTrackingData()
                }),
            creativeFilter: (type: string, value: unknown) =>
                analytics.trackEvent('creative:filter', {type, value, ...addBaseTrackingData()}),
            creativeFullscreen: (creative: Creative) =>
                analytics.trackEvent('creative:fullscreen', {
                    type: event,
                    ...addBaseTrackingData(),
                    ...creative.toTracking()
                }),
            creativeListDownload: (params: {creativeList: string[]}) =>
                analytics.trackEvent('creative:downloadList', {
                    creativeIdList: params.creativeList,
                    ...addBaseTrackingData()
                }),
            creativeSearch: onCreativeAdContentSearch,
            creativeSort: (sortOption: string) => {
                const prettySortLabel = sortOption === 'score' ? 'relevancy' : sortOption;
                analytics.trackEvent('creative:sort', {
                    sortOption: prettySortLabel,
                    ...addBaseTrackingData()
                });
            },

            nextCreativeMouseClick: (creativeIndex: number) => {
                analytics.trackEvent('nextCreative:mouseClick', {
                    ...addBaseTrackingData(),
                    creativeIndex
                });
            },
            previousCreativeMouseClick: (creativeIndex: number) => {
                analytics.trackEvent('previousCreative:mouseClick', {
                    ...addBaseTrackingData(),
                    creativeIndex
                });
            },
            creativeCarouselCreativeClick: (creativeIndex: number, creative: Creative) => {
                analytics.trackEvent('creativeCarousel:creativeClick', {
                    ...addBaseTrackingData(),
                    creativeIndex,
                    ...creative.toTracking()
                });
            },

            nextCreativeKeyboardPress: (creativeIndex: number) => {
                analytics.trackEvent('nextCreative:keyboardPress', {
                    ...addBaseTrackingData(),
                    creativeIndex
                });
            },
            previousCreativeKeyboardPress: (creativeIndex: number) => {
                analytics.trackEvent('previousCreative:keyboardPress', {
                    ...addBaseTrackingData(),
                    creativeIndex
                });
            },

            adContentSearchHelpOpen: () => {
                analytics.trackEvent('adContentSearchHelp:open', {...addBaseTrackingData()});
            },

            /** Want to try something different and start submitting entity names when tracking
             * entities. its a pain trying to figure out what a user did with IDs, as you cannot
             * join any table in the `segment` DB that would give you entity names. We usually had
             * to build dataentry URLs, which was a pain to write a query for but also for the rest
             * of the team to see what people queried for
             */
            filterSubmit: (data: {
                submittedIds: QueryStringFilters;
                submittedNames: QueryStringFilters;
                creativeSort: CreativeListOrderBy;
            }) => {
                analytics.trackEvent('filter:submit', {
                    ...addBaseTrackingData(),
                    ...data
                });
            },

            creativeDisplayChange: (displayMode: CreativeDisplay) => {
                analytics.trackEvent('creativeDisplay:change', {
                    displayMode,
                    ...addBaseTrackingData()
                });
            },

            creativeTableColumnSelect: onTableColumnSelect,

            /** searching for a filter that has a searchable infinite list */
            filterSearch: onFilterSearch,

            // Creatives
            creativesDownloadOpen: () =>
                analytics.trackInteraction('creativesDownloadOpen', {...addBaseTrackingData()}),

            creativesDownload: (
                payload: {type: 'csv' | 'media'} | {type: 'pdf'; timeTakenMs: number}
            ) => analytics.trackEvent('creatives:download', {...payload, ...addBaseTrackingData()}),

            // Media Value
            mediaValueFilter: (type: string, value: unknown) =>
                analytics.trackEvent('mediaValue:filter', {type, value}),

            // Data Export
            dataExportTemplateSelection: (payload: {template: string; type: string}) => {
                analytics.trackEvent('dataExport:templateSelection', payload);
            },
            dataExportCsvDownload: (payload: {type: string; filters: string}) => {
                analytics.trackEvent('dataExport:csvDownload', payload);
            },

            // dashboard
            shortcutClick: (payload: Brand | Category | Industry) =>
                analytics.trackEvent('shortcut:click', payload.trackingType),
            shortcutEdit: () => analytics.trackEvent('shortcut:edit'),

            // Charts
            chartDownload: (payload: {
                node: {name: string; type: string};
                lens: Array<string>;
                filter: unknown;
            }) => {
                analytics.trackEvent('chart:download', payload);
            },
            dataTableCsvExport(payload: BreakdownDefinitionUpsert['filter']) {
                analytics.trackEvent('dataTable:csvExport', payload);
            },

            // news section
            newsArticleView(payload: {articleName: string}) {
                analytics.trackEvent('newsArticle:read', {...payload, ...addBaseTrackingData()});
            },

            // public creative CTA
            registerCtaClick: (identifier: string) =>
                analytics.trackEvent('registerCTA:click', {identifier}),

            // user registration completed:
            registerComplete: () =>
                analytics.trackEvent('register:complete', {...addBaseTrackingData()}),

            dateSelection: (payload: {
                type: 'spend' | 'creative';
                date: MediaDateRangeFilter | CreativeDateRangeFilter;
            }) => {
                // doing this because its unreadable if we just dump the whole date object as is
                const {type, date} = payload;
                if (date.absolute) {
                    const {endDate, startDate} = date.absolute;
                    analytics.trackEvent('datePicker:select', {
                        type,
                        startDate,
                        endDate,
                        ...addBaseTrackingData()
                    });
                }
                if (date.relative) {
                    analytics.trackEvent('datePicker:select', {
                        type,
                        rollingMonths: date.relative.months,
                        ...('includeForecasted' in date.relative && {
                            includeForecastedData: date.relative.includeForecasted
                        }),
                        ...addBaseTrackingData()
                    });
                }
            }
        }),
        [analytics]
    );
    return tracking;
}
