import {Button, ErrorBoundary, Navigation} from 'bigdatr-style';
import {Box} from 'bigdatr-style/layout';
import React from 'react';
import {Switch} from 'react-router-dom';
import {routes} from './Router';
import useEventTracking from './useEventTracking';

export const publicRoutePaths = ['/creative/:creativeId/'];

function PublicRoutes() {
    const {registerCtaClick} = useEventTracking();

    return (
        <>
            <Navigation
                items={[
                    {
                        children: (
                            <Box onClick={() => registerCtaClick('navigationBar')}>
                                <Button href={`${process.env.BIGDATR_AUTH_URL}/register`}>
                                    Free Trial
                                </Button>
                            </Box>
                        )
                    },
                    {
                        to: {pathname: '/login', state: {from: window.location.pathname}},
                        children: <Button secondary children="Log in" />
                    }
                ]}
            />
            <ErrorBoundary>
                <Switch>{routes.singleCreative}</Switch>
            </ErrorBoundary>
        </>
    );
}

export default PublicRoutes;
