import {
    MediaValueCsvQueryVariables,
    MediaValueQueryDistributionArgs,
    MediaValueQueryComparisonArgs,
    DistributionQueryVariables,
    ComparisonQueryVariables,
    MediaValueRowFilterInput
} from '~/graphql-enty/graphql';
import {MediaValueRowsQueryVariables} from '~/graphql-enty/graphql';
import {MediaValueRowsQuery} from '~/graphql-enty/graphql';
import {MediaValueCsvQuery} from '~/graphql-enty/graphql';
import {EntityStrict, Meta} from '~/app/Endpoints';
import {GraphqlRequest} from 'bigdatr-style';
import {add, startOf} from 'stringdate';

const csv = EntityStrict<MediaValueCsvQueryVariables>(() => import('./data/MediaValueCsv.graphql'));
const expenditureReport = EntityStrict<MediaValueRowsQueryVariables>(
    () => import('./data/MediaValueRows.graphql')
);
const comparison = EntityStrict<ComparisonQueryVariables>(
    () => import('./data/ComparisonRows.graphql')
);
const distribution = EntityStrict<DistributionQueryVariables>(
    () => import('./data/DistributionRows.graphql')
);

export type MediaValueApiType = {
    mediaValue: {
        expenditureReport: GraphqlRequest<MediaValueRowsQuery, MediaValueRowsQueryVariables>;
        csv: GraphqlRequest<MediaValueCsvQuery, MediaValueCsvQueryVariables>;
        distribution: GraphqlRequest<
            {mediaValue: {distribution: TemplatedMediaValueResponse}},
            MediaValueQueryDistributionArgs
        >;
        comparison: GraphqlRequest<
            {mediaValue: {comparison: TemplatedMediaValueResponse}},
            MediaValueQueryComparisonArgs
        >;
    };
};

// This is a bit hacky but making all date pickers exclusive
// turned out to be too much work. So this just mutates the end date to
// be one more than asked for.
function makeDateRangeExclusive(dateRangeFilter: MediaValueRowsQueryVariables['dateRangeFilter']) {
    return {
        ...dateRangeFilter,
        ...(dateRangeFilter?.absolute && {
            absolute: {
                startDate: dateRangeFilter.absolute.startDate,
                endDate: startOf('month')(add('P1M')(dateRangeFilter.absolute.endDate))
            }
        })
    };
}

function makePeriodSelectionExclusive(
    periodSelection: ComparisonQueryVariables['periodSelection']
) {
    const {reportingPeriod, comparisonPeriod} = periodSelection;

    return {
        ...periodSelection,
        reportingPeriod: {
            ...reportingPeriod,
            absolute: reportingPeriod.absolute
                ? makeDateRangeExclusive({absolute: reportingPeriod.absolute}).absolute
                : undefined
        },
        comparisonPeriod: comparisonPeriod
            ? {
                  ...comparisonPeriod,
                  absolute: comparisonPeriod.absolute
                      ? makeDateRangeExclusive({absolute: comparisonPeriod.absolute}).absolute
                      : undefined
              }
            : undefined
    };
}

export const MediaValueApi = {
    mediaValue: {
        expenditureReport: async (v: MediaValueRowsQueryVariables, meta: Meta) => {
            const variables = {...v, dateRangeFilter: makeDateRangeExclusive(v.dateRangeFilter)};
            const {
                columns = [],
                columnFilter,
                dateRangeFilter,
                pagination,
                changesetId
            } = variables;
            const response = await expenditureReport(variables, meta);

            // Need to manipulate rows to indicate what page does the item exists
            // This is needed for the matrix chart pagination to check if the value is final
            // or being cutoff by pagination
            response.mediaValue.rows.rows = response.mediaValue.rows.rows.map((r) => ({
                ...r,
                page: response.mediaValue.rows.pageInfo.currentPage
            }));

            response.mediaValue.rows.id = JSON.stringify([
                columns,
                columnFilter,
                dateRangeFilter,
                pagination?.orderBy,
                changesetId
            ]);
            return response;
        },
        csv: async (v: MediaValueCsvQueryVariables, meta: Meta) => {
            const variables = {...v, dateRangeFilter: makeDateRangeExclusive(v.dateRangeFilter)};
            return csv(variables, meta);
        },
        comparison: async (vars: ComparisonQueryVariables, meta: Meta) => {
            const {rows} = vars;

            // Make periodSelection exclusive
            const exclusivePeriodSelection = makePeriodSelectionExclusive(vars.periodSelection);

            // removing un-necessary fields from row
            const requestRows = rows.map((row: MediaValueRowFilterInput) => {
                return {
                    id: row.id,
                    label: row.label,
                    filter: row.filter
                };
            });

            const response = await comparison(
                {periodSelection: exclusivePeriodSelection, rows: requestRows},
                meta
            );
            return response;
        },
        distribution: async (vars: DistributionQueryVariables, meta: Meta) => {
            const {breakdownColumn, rows} = vars;

            // removing un-necessary fields from row
            const requestRows = rows.map((row: MediaValueRowFilterInput) => {
                return {
                    id: row.id,
                    label: row.label,
                    filter: row.filter
                };
            });

            const response = await distribution(
                {
                    dateRangeFilter: makeDateRangeExclusive(vars.dateRangeFilter),
                    breakdownColumn,
                    rows: requestRows
                },
                meta
            );

            return response;
        }
    }
};

// this isn't ideal to type the return values from the API like this, but the graphql schema
// specifies the return type to just be JSON

type Row = {
    id: string;
    label: string;
    filter: {
        adType?: string[];
        brand?: string[];
        category?: string[];
        industry?: string[];
        mediaOwner?: string[];
        mediaType?: string[];
        product?: string[];
        publisher?: string[];
        region?: string[];
    };
    cellValues: {column: string; value: number}[];
};

export type ColumnMeta = {
    isPercentage: boolean;
    isSpend: boolean;
    isChange?: boolean;
    column: string;
};

type TemplatedMediaValueResponse = {
    rows: Row[];
    columnMeta: ColumnMeta[];
};
