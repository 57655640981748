import {
    Button,
    MenuItem,
    MoreMenu,
    styled,
    Tooltip,
    useAnalytics,
    useAuth,
    useViewer
} from 'bigdatr-style';
import {Input, Text} from 'bigdatr-style';
import {Box, Flex, FlexColumn, Relative, Wrapper} from 'bigdatr-style/layout';
import {AU} from 'bigdatr-style/src/team/Team';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {ChevronSmallDown, Inbox, QuestionCircle, Settings, User2} from 'react-swm-icon-pack';
import DropdownSwitchTeams from '~/affordance/DropdownSwitchTeam';
import {useCountry} from '~/feature/country/CountryContext';
import CountrySwitch from '~/feature/country/CountrySwitch';
import {HELP_DOC_LINKS, NotificationBadge} from './AuthenticatedNavigation';
import {useGlobalState} from './GlobalState';
import {useRoutes} from './Router';

export default function AppHeader() {
    const viewer = useViewer();
    const {changeTeam} = useAuth();
    const {news, headerSearch} = useGlobalState();
    const {hasUnreadNews} = news;
    const {setDebouncedSearch} = headerSearch;
    const {country} = useCountry();
    const routes = useRoutes();
    const analytics = useAnalytics();
    const history = useHistory();

    return (
        <Box borderBottom="outline" backgroundColor="navigationBackground">
            <Wrapper
                maxWidth="120rem"
                px={3}
                py={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap={3}
            >
                {country === AU ? (
                    <Box width={{lg: '20rem'}}>
                        <Input
                            data-testid="header-search"
                            type="search"
                            onDebounce={setDebouncedSearch}
                            debounce={500}
                            onChange={(value) => {
                                if (value.trim().length > 0) routes.search.push({});
                                else history.goBack();
                            }}
                            clearable
                            placeholder="Search"
                        />
                    </Box>
                ) : (
                    <div />
                )}

                <Flex alignItems="center">
                    <CountrySwitch />

                    <Tooltip content="Settings" offset={12}>
                        <Button thin tertiary to="/settings">
                            <Settings />
                        </Button>
                    </Tooltip>
                    <Tooltip content="Help" offset={12}>
                        <Button thin tertiary href={HELP_DOC_LINKS[country]} target="_blank">
                            <QuestionCircle />
                        </Button>
                    </Tooltip>
                    <Tooltip content="News" offset={12}>
                        <Button thin tertiary to={routes.notificationNews.to({})}>
                            <Relative>
                                <Inbox aria-label="News" />
                                {hasUnreadNews && (
                                    <NotificationBadge data-testid="unread-notifications-badge">
                                        1
                                    </NotificationBadge>
                                )}
                            </Relative>
                        </Button>
                    </Tooltip>
                    <MoreMenu
                        thin={false}
                        icon={({isOpen}) => {
                            return (
                                <ControlWrap isOpen={isOpen}>
                                    <User2 />
                                    <Text textStyle="ellipsis">{viewer.currentTeam.name}</Text>
                                    <ChevronSmallDown data-testid="chevron-down" />
                                </ControlWrap>
                            );
                        }}
                    >
                        {() => {
                            return (
                                <>
                                    <DropdownSwitchTeams viewer={viewer} changeTeam={changeTeam} />

                                    <FlexColumn py={2}>
                                        <MenuItem small onClick={() => analytics.chat()}>
                                            Contact Us
                                        </MenuItem>
                                        <MenuItem small href="https://bigdatr.com/licenseagreement">
                                            License Agreement
                                        </MenuItem>
                                        <MenuItem small href="https://bigdatr.com/terms-of-use">
                                            Terms of Use
                                        </MenuItem>
                                        <MenuItem small href="https://bigdatr.com/privacy-policy">
                                            Privacy Policy
                                        </MenuItem>
                                        <MenuItem small to="/logout">
                                            Logout
                                        </MenuItem>
                                    </FlexColumn>
                                </>
                            );
                        }}
                    </MoreMenu>
                </Flex>
            </Wrapper>
        </Box>
    );
}

const ControlWrap = styled.div<{isOpen: boolean}>`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    max-width: 14rem;

    [data-testid='chevron-down'] {
        transition: transform ${({theme}) => theme.transition.quick};

        ${(p) => (p.isOpen ? `transform: rotate(-180deg);` : '')}
    }
`;
