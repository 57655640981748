import {IconFlagAU} from 'bigdatr-style/icon';
import React from 'react';
import useFeatureFlags from '~/util/useFeatureFlags';

export default function IconAU() {
    const {navigationV2} = useFeatureFlags();
    if (!navigationV2) return <IconFlagAU size="1.5rem" />;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="21"
            fill="none"
            viewBox="0 0 32 21"
        >
            <path
                fill="#41479B"
                d="M31.448 20.923H.552a.551.551 0 0 1-.552-.55V.55C0 .247.247 0 .552 0h30.896c.305 0 .552.247.552.55v19.822c0 .305-.247.551-.552.551Z"
            />
            <path
                fill="#F5F5F5"
                d="M15.448 0h-.958L9.38 3.342V0H6.62v3.342L1.51 0H.552A.551.551 0 0 0 0 .55v.437l4.385 2.867H0v2.753h4.385L0 9.474v.437c0 .304.247.55.552.55h.958L6.62 7.12v3.341h2.76V7.12l5.11 3.341h.958a.551.551 0 0 0 .552-.55v-.437l-4.385-2.867H16V3.854h-4.385L16 .987V.551A.551.551 0 0 0 15.448 0Z"
            />
            <path
                fill="#FF4B55"
                d="M16 4.405H8.828V0H7.172v4.405H0v1.652h7.172v4.404h1.656V6.057H16V4.405Z"
            />
            <path
                fill="#FF4B55"
                d="M6.154 3.692.267 0A.529.529 0 0 0 0 .45v.027l5.126 3.215h1.028ZM10.28 3.692 16 .265A.572.572 0 0 0 15.516 0h-.124L9.231 3.692h1.05ZM5.718 6.77 0 10.19c.099.16.277.271.49.271h.108L6.769 6.77H5.72ZM16 10.02l-5.145-3.25H9.846l5.842 3.691a.53.53 0 0 0 .312-.44Z"
            />
            <path
                fill="#F5F5F5"
                d="m8.116 13.627.408 1.38 1.308-.534c.111-.046.213.085.145.186l-.8 1.187 1.223.715c.104.06.068.223-.052.232l-1.403.1.216 1.425c.019.121-.128.194-.209.103L8 17.359l-.952 1.062c-.081.09-.228.018-.21-.103l.217-1.425-1.403-.1c-.12-.009-.156-.171-.052-.232l1.222-.715-.799-1.187c-.068-.101.034-.232.145-.186l1.308.534.408-1.38a.12.12 0 0 1 .232 0ZM24.674 3.736l.204.69.653-.266c.056-.023.107.042.073.093l-.4.593.611.357c.053.03.034.112-.025.116l-.702.05.108.713c.01.06-.064.097-.105.051l-.476-.53-.476.53c-.04.046-.113.01-.104-.051l.108-.712-.702-.05c-.06-.005-.078-.086-.026-.117l.611-.357-.399-.593c-.034-.051.017-.116.073-.093l.653.266.204-.69a.06.06 0 0 1 .117 0ZM19.75 8.66l.204.69.654-.267c.056-.023.107.042.073.093l-.4.593.611.358c.053.03.034.111-.026.115l-.701.05.108.713c.01.06-.064.097-.105.052l-.476-.531-.476.53c-.04.046-.114.01-.104-.051l.108-.712-.702-.05c-.06-.005-.078-.086-.026-.116l.611-.358-.399-.593c-.034-.05.017-.116.072-.093l.654.266.204-.69a.06.06 0 0 1 .116 0ZM28.981 6.813l.204.69.654-.266c.056-.023.106.042.072.093l-.399.593.611.357c.052.03.034.112-.026.116l-.702.05.109.713c.009.06-.064.097-.105.051l-.476-.53-.476.53c-.04.046-.114.01-.105-.051l.109-.712-.702-.05c-.06-.005-.078-.086-.026-.117l.611-.357-.4-.593c-.033-.051.017-.116.073-.093l.654.266.204-.69a.06.06 0 0 1 .116 0ZM24.674 15.429l.204.69.653-.267c.056-.023.107.042.073.093l-.4.593.611.358c.053.03.034.111-.025.116l-.702.05.108.712c.01.061-.064.097-.105.052l-.476-.531-.476.53c-.04.046-.113.01-.104-.05l.108-.713-.702-.05c-.06-.005-.078-.086-.026-.116l.611-.358-.399-.593c-.034-.05.017-.116.073-.093l.653.267.204-.69a.06.06 0 0 1 .117 0ZM26.08 9.903l-.186.58-.585.005c-.076 0-.107.102-.046.15l.47.363-.176.584c-.023.075.06.138.12.092l.477-.357.476.357c.061.046.143-.017.12-.092L26.575 11l.47-.364c.062-.047.03-.148-.045-.149l-.585-.005-.185-.58a.078.078 0 0 0-.15 0Z"
            />
        </svg>
    );
}
