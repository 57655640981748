import {safeLazyImport, TableSortState} from 'bigdatr-style';
import {createRouterContext, LazyRoute, BoringRoute, Parse} from 'bigdatr-style';
import {
    isChartType,
    ReportRouteArgs,
    DraftReportItem
} from '~/report/data/ReportItemDefinitionTypes';
import UserAdminRoutes from '~/user/UserAdminRoutes';
import PdfRoutes from '~/pdf/PdfRoutes';
import type {QueryStringFilters} from '~/feature/filter/types';
import type {CreativeDisplay} from '~/feature/creative/display-toggle';
import {CreativeListOrderBy} from '~/creative/affordance/CreativeListSortBy';
import {CreativeExploreArgs} from '~/creative/CreativeExploreView';
import OnboardRoutes from '~/onboard/Routes';
import MediaValueRoutes from '~/feature/media-value/MediaValueRoutes';
import NotificationRoutes from '~/feature/notifications/NotificationRoutes';
import NoAccessRoutes from '~/no-access-routes/NoAccessRoutes';
import {KeywordListArgs} from '~/keywords/KeywordManagerPage';
import {SingleCreativePageArgs} from '~/creative/SingleCreativePage';

const ReportRoute = safeLazyImport(() => import('~/report/ReportRoute'));
const ReportList = safeLazyImport(() => import('~/report/ReportList'));
const ExportRoute = safeLazyImport(() => import('~/pages/explore-media-value/ExportPage'));
const DashboardRoute = safeLazyImport(() => import('~/dashboard/DashboardRoute'));
const StyleguideAffordances = safeLazyImport(() => import('~/styleguide/StyleguideAffordances'));
const StyleguideColors = safeLazyImport(() => import('~/styleguide/StyleguideColors'));
const StyleguideTypography = safeLazyImport(() => import('~/styleguide/StyleguideTypography'));
const SegmentList = safeLazyImport(() => import('~/segment/SegmentList'));
const SegmentItem = safeLazyImport(() => import('~/segment/SegmentItem'));
const Search = safeLazyImport(() => import('~/search/Search'));
const CreativeExploreView = safeLazyImport(() => import('~/creative/CreativeExploreView'));
const SingleCreativePage = safeLazyImport(() => import('~/creative/SingleCreativePage'));
const KeywordManagerPage = safeLazyImport(() => import('~/keywords/KeywordManagerPage'));

const {routes, RoutesProvider, useRoutes} = createRouterContext({
    search: LazyRoute<{q?: string}>({
        path: '/search',
        parse: {
            q: Parse.query.string((x) => x)
        },
        component: Search
    }),
    reportList: BoringRoute({
        path: '/report',
        component: ReportList
    }),
    report: LazyRoute<ReportRouteArgs>({
        path: '/report/:id',
        parse: {
            id: Parse.param.string((x) => x || ''),
            draftReportItem: Parse.state((x) => (x as DraftReportItem) || undefined),
            triggerCreateModal: Parse.state((x) => x as boolean | undefined)
        },
        component: ReportRoute
    }),
    dashboard: BoringRoute({
        path: '/',
        component: DashboardRoute
    }),
    /** @deprecated use exploreMediaValue */
    dataExport: LazyRoute<{
        filter?: QueryStringFilters;
        chartType?: string;
        columns?: string[];
        excludedColumns?: string[];
    }>({
        path: '/export',
        parse: {
            filter: Parse.query.JSON(
                (x) =>
                    (x ?? {
                        mediaDateRange: {relative: {months: 12, includeForecasted: true}}
                    }) as QueryStringFilters
            ),
            columns: Parse.query.JSON((x) => (Array.isArray(x) ? x : ['brand'])),
            excludedColumns: Parse.query.JSON((x) => (Array.isArray(x) ? x : [])),
            chartType: Parse.query.string((x) => (x && isChartType(x) ? x : 'Matrix'))
        },
        component: ExportRoute
    }),

    segmentList: BoringRoute({
        path: '/segment',
        component: SegmentList
    }),
    segment: LazyRoute<{id: string}>({
        path: '/segment/:id',
        parse: {
            id: Parse.param.string((x) => x || '')
        },
        component: SegmentItem
    }),

    exploreCreative: LazyRoute<CreativeExploreArgs>({
        path: '/explore-creative',
        component: CreativeExploreView,
        parse: {
            filter: Parse.query.JSON(
                (x) => (x || {creativeDateRange: {relative: {months: 3}}}) as QueryStringFilters
            ),
            tableSort: Parse.query.JSON((x) => x as TableSortState),
            display: Parse.query.string((q) => q as CreativeDisplay),
            creativeModalId: Parse.query.string((q) => q),
            creativeListOrderBy: Parse.query.JSON((x) => x as CreativeListOrderBy),
            page: Parse.query.number((q) => q)
        }
    }),

    singleCreative: LazyRoute<SingleCreativePageArgs>({
        path: '/creative/:creativeId',
        component: SingleCreativePage,
        parse: {
            creativeId: Parse.param.string((x) => x)
        }
    }),

    keywordList: LazyRoute<KeywordListArgs>({
        path: '/keywords',
        component: KeywordManagerPage,
        parse: {
            highlightIds: Parse.query.JSON((x) => (Array.isArray(x) ? x : undefined))
        }
    }),

    ...NoAccessRoutes,
    ...MediaValueRoutes,
    ...NotificationRoutes,
    ...PdfRoutes,
    ...UserAdminRoutes,
    ...OnboardRoutes,
    styleguideColors: BoringRoute({
        path: '/styleguide/colors',
        component: StyleguideColors
    }),
    styleguideTypography: BoringRoute({
        path: '/styleguide/typography',
        component: StyleguideTypography
    }),
    styleguideAffordances: BoringRoute({
        path: '/styleguide/affordances',
        component: StyleguideAffordances
    })
});

export {routes, RoutesProvider, useRoutes};
