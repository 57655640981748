import React from 'react';

export default function IconGB() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="21"
            fill="none"
            viewBox="0 0 32 21"
        >
            <path
                fill="#41479B"
                d="M31.448 20.965H.552A.552.552 0 0 1 0 20.413V.552C0 .247.247 0 .552 0h30.896c.305 0 .552.247.552.552v19.861a.552.552 0 0 1-.552.552Z"
            />
            <path
                fill="#F5F5F5"
                d="M32 .552A.552.552 0 0 0 31.448 0h-2.469L18.76 6.697V0H13.24v6.697L3.021 0H.55A.552.552 0 0 0 0 .552v1.427l8.77 5.745H0v5.517h8.77L0 18.987v1.427c0 .304.247.551.552.551H3.02l10.221-6.696v6.696h5.517V14.27l10.222 6.696h2.468a.552.552 0 0 0 .552-.552v-1.426l-8.77-5.746H32V7.724h-8.77L32 1.98V.552Z"
            />
            <path
                fill="#FF4B55"
                d="M32 8.828H17.654V0h-3.31v8.828H0v3.31h14.345v8.827h3.31v-8.827H32v-3.31Z"
            />
            <path
                fill="#FF4B55"
                d="M11.184 13.241.016 20.491c.039.266.259.474.536.474h.76l11.899-7.724h-2.027ZM21.65 13.241h-2.027l11.881 7.713a.546.546 0 0 0 .496-.54v-.454L21.65 13.24ZM0 1.16l10.111 6.564h2.027L.322.054A.55.55 0 0 0 0 .552v.609ZM20.786 7.724 31.98.457A.544.544 0 0 0 31.448 0h-.79l-11.9 7.724h2.028Z"
            />
        </svg>
    );
}
