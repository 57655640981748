import {skipToken, useQuery} from '@tanstack/react-query';
import {Brand} from 'bigdatr-style';
import useClientMainAuthorizedFetch from '../clientMainAuthorisedFetch';
import {Search} from './graphqlQueries';

export function useAdvertiserSearch(query: string, options?: {enabled: boolean}) {
    const request = useClientMainAuthorizedFetch();
    const enabled = options?.enabled ?? true;
    return useQuery({
        queryKey: ['advertiserSearch', query],
        queryFn: enabled
            ? async () => {
                  const response = await request(Search, {
                      search: {
                          type: 'brand',
                          limitToViewerIndustries: false,
                          query,
                          pageNum: 1,
                          pageSize: 25
                      }
                  });
                  if (!response.searchV2?.search.items)
                      throw new Error('No advertisers returned from searchV2');

                  const rawEntities = response.searchV2?.search.items;
                  const brands = rawEntities.flatMap((entity) => {
                      if (entity.type === 'brand') return [new Brand(entity)];
                      return [];
                  });
                  return brands;
              }
            : skipToken
    });
}
