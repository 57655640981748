import {IconFlagNZ} from 'bigdatr-style/icon';
import React from 'react';
import useFeatureFlags from '~/util/useFeatureFlags';

export default function IconNZ() {
    const {navigationV2} = useFeatureFlags();
    if (!navigationV2) return <IconFlagNZ size="1.5rem" />;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="21"
            fill="none"
            viewBox="0 0 32 21"
        >
            <path
                fill="#41479B"
                d="M31.448 20.965H.552A.552.552 0 0 1 0 20.415V.552C0 .247.247 0 .552 0h30.896c.305 0 .552.247.552.552v19.862a.552.552 0 0 1-.552.551Z"
            />
            <path
                fill="#F5F5F5"
                d="M15.448 0h-.958L9.38 3.348V0H6.62v3.348L1.51 0H.552A.552.552 0 0 0 0 .552v.437l4.385 2.873H0v2.759h4.385L0 9.493v.438c0 .305.247.552.552.552h.958l5.11-3.349v3.349h2.76V7.134l5.11 3.349h.958A.552.552 0 0 0 16 9.93v-.438l-4.385-2.872H16V3.862h-4.385L16 .99V.552A.552.552 0 0 0 15.448 0Z"
            />
            <path
                fill="#FF4B55"
                d="M16 4.414H8.828V0H7.172v4.414H0v1.655h7.172v4.414h1.656V6.069H16V4.414Z"
            />
            <path
                fill="#FF4B55"
                d="M6.07 3.862.262.093A.543.543 0 0 0 0 .552V.58l5.056 3.282h1.013ZM10.393 3.862 15.916.277A.544.544 0 0 0 15.448 0h-.119L9.38 3.862h1.014ZM5.592 6.62.08 10.2a.545.545 0 0 0 .473.283h.104l5.95-3.862H5.592ZM15.991 9.974l-5.166-3.353H9.81l5.867 3.808a.546.546 0 0 0 .313-.455ZM24.717 17.49a.113.113 0 0 1-.064-.02L24 17.002l-.653.466a.11.11 0 0 1-.17-.123l.243-.765-.646-.476a.11.11 0 0 1 .065-.2l.803-.006.253-.761a.111.111 0 0 1 .105-.076c.048 0 .09.03.105.075l.254.762.802.006a.11.11 0 0 1 .065.2l-.645.476.242.765a.111.111 0 0 1-.106.144Z"
            />
            <path
                fill="#F5F5F5"
                d="m24 15.172.279.837.881.006-.709.524.266.84-.717-.512-.717.512.266-.84-.71-.524.882-.006.279-.837Zm0-.22a.22.22 0 0 0-.21.15l-.228.687-.724.006a.22.22 0 0 0-.13.398l.583.43-.219.69a.22.22 0 0 0 .34.246l.588-.421.589.42a.221.221 0 0 0 .338-.245l-.218-.69.582-.43a.22.22 0 0 0-.13-.398l-.723-.006-.229-.686a.22.22 0 0 0-.21-.151Z"
            />
            <path
                fill="#FF4B55"
                d="M24.717 8.595a.112.112 0 0 1-.064-.02L24 8.106l-.653.467a.112.112 0 0 1-.13 0 .111.111 0 0 1-.04-.123l.243-.765-.646-.477a.11.11 0 0 1 .065-.2l.802-.005.254-.761a.11.11 0 0 1 .21 0l.254.76.802.007c.048 0 .09.031.104.076a.111.111 0 0 1-.04.123l-.645.477.243.765a.111.111 0 0 1-.106.144Z"
            />
            <path
                fill="#F5F5F5"
                d="m24 6.277.279.836.881.007-.709.524.266.84L24 7.972l-.717.512.266-.84-.71-.524.882-.007.279-.836Zm0-.22a.22.22 0 0 0-.21.15l-.228.687-.724.006a.22.22 0 0 0-.13.398l.583.43-.219.69a.22.22 0 0 0 .34.246L24 8.243l.589.42a.221.221 0 0 0 .338-.245l-.218-.69.582-.43a.22.22 0 0 0-.13-.398l-.723-.006-.229-.686a.22.22 0 0 0-.21-.151Z"
            />
            <path
                fill="#FF4B55"
                d="M28.58 11.354a.111.111 0 0 1-.065-.021l-.653-.467-.653.467a.112.112 0 0 1-.129 0 .111.111 0 0 1-.04-.123l.242-.766-.646-.476a.11.11 0 0 1-.04-.123.111.111 0 0 1 .105-.077l.802-.006.254-.76a.111.111 0 0 1 .105-.076c.048 0 .09.03.105.075l.254.761.802.006c.048.001.09.032.105.077a.111.111 0 0 1-.04.123l-.645.476.242.766a.111.111 0 0 1-.106.143Z"
            />
            <path
                fill="#F5F5F5"
                d="m27.862 9.036.279.836.881.007-.709.524.266.84-.717-.513-.717.513.266-.84-.71-.524.882-.007.279-.836Zm0-.22a.22.22 0 0 0-.21.15l-.228.687-.724.005a.22.22 0 0 0-.13.399l.583.43-.218.69a.22.22 0 0 0 .338.245l.589-.42.589.42a.22.22 0 0 0 .339-.246l-.219-.69.582-.43a.22.22 0 0 0-.129-.398l-.724-.005-.229-.687a.221.221 0 0 0-.209-.15Z"
            />
            <path
                fill="#FF4B55"
                d="M20.855 12.457a.111.111 0 0 1-.064-.02l-.653-.468-.653.467a.112.112 0 0 1-.13 0 .111.111 0 0 1-.04-.123l.243-.765-.646-.477a.11.11 0 0 1-.04-.123.11.11 0 0 1 .105-.076l.802-.006.254-.761a.111.111 0 0 1 .105-.076c.047 0 .09.03.105.076l.254.76.802.007c.048 0 .09.031.105.076a.111.111 0 0 1-.04.123l-.646.477.243.765a.111.111 0 0 1-.106.144Z"
            />
            <path
                fill="#F5F5F5"
                d="m20.138 10.14.279.836.881.006-.71.524.267.84-.717-.512-.717.512.266-.84-.71-.524.882-.006.279-.837Zm0-.221a.22.22 0 0 0-.21.15l-.229.687-.723.006a.22.22 0 0 0-.13.398l.583.43-.219.69a.22.22 0 0 0 .34.246l.588-.42.588.42a.221.221 0 0 0 .34-.246l-.219-.69.582-.43a.22.22 0 0 0-.13-.398l-.723-.006-.229-.686a.22.22 0 0 0-.21-.151Z"
            />
        </svg>
    );
}
