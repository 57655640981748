import {useBetween} from 'use-between';
import {useLocalStorage} from 'bigdatr-style';

export const openNavWidth = '16rem';
export const closedNavWidth = '3.625rem';
function useNavigationState() {
    const [navOpenLocalStorage, setNavOpen] = useLocalStorage('bd-app-sidebarOpen', false);
    const navOpen = Boolean(navOpenLocalStorage);
    const navWidth = navOpen ? openNavWidth : closedNavWidth;
    const mainContentAvailableWidth = `calc(100vw - ${navWidth} - 1rem)`;

    const headerHeight = '2rem';

    return {
        navOpen,
        setNavOpen,
        navWidth,
        mainContentAvailableWidth,
        headerHeight
    };
}

export default function () {
    return useBetween(useNavigationState);
}
