import {useAnalytics, Link, Text, useViewer, Button} from 'bigdatr-style';
import {Ul} from 'bigdatr-style/layout';
import React from 'react';
import Api from '~/app/EntityApi';
import {useRoutes} from '~/app/Router';
import formatViewerDataToHubspotData from '../../formatViewerDataToHubspotData';

export default function UiImprovements() {
    const routes = useRoutes();
    const analytics = useAnalytics();
    const viewer = useViewer();

    const submitTrackingForm = Api.tracking.submitHubspotForm.useRequest();

    return (
        <>
            <p>We&apos;ve redesigned your competitor tools to help you work faster:</p>

            <Ul>
                <li>
                    Larger creative displays and smoother scrolling make reviewing content quicker
                    and more intuitive.
                </li>
                <li>
                    Find exactly what you need in seconds by selecting filters and running complex
                    searches in one, simplified view.
                </li>
                <li>
                    With enhanced navigation across the platform, you can now access your most-used
                    features with fewer clicks.
                </li>
            </Ul>

            <p>
                <Button
                    onClick={() => {
                        const data = formatViewerDataToHubspotData(viewer);
                        const formInput = Object.entries(data).map(([fieldName, fieldValue]) => {
                            return {
                                name: fieldName,
                                value:
                                    typeof fieldValue === 'string'
                                        ? fieldValue
                                        : JSON.stringify(fieldValue)
                            };
                        });

                        submitTrackingForm.request({
                            formId: 'fd01e124-98b5-463d-84ad-8318805517d6',
                            formInput
                        });
                        routes.exploreCreative.push({});
                    }}
                >
                    Try it now →
                </Button>
            </p>

            <p>Your feedback matters! Tell us what you think as you explore these updates.</p>

            <p>
                <Text textStyle="strong">Questions?</Text> Chat with us or request a team
                walkthrough <Link onClick={() => analytics.chat()}>→</Link>
            </p>
        </>
    );
}
