import {graphql} from '~/graphql-react-query/';

export const PublicCreativeItem = graphql(`
    query CreativeItemV2($creativeId: ID!) {
        creativeV2 {
            publicCreativeItemV2(creativeId: $creativeId) {
                id
                creative
                firstAppeared
                lastAppeared
                status
                adType {
                    id
                    name
                    canonicalName
                }
                categoryList {
                    id
                    name
                }
                mediaOwnerList {
                    id
                    name
                }
                mediaTypeList {
                    id
                    name
                    canonicalName
                }
                publisherList {
                    id
                    name
                }
                publicationList {
                    id
                    name
                }
                regionList {
                    id
                    name
                }
                productList {
                    id
                    name
                    brand {
                        id
                        name
                    }
                }
                brandList {
                    id
                    name
                }
                industryList {
                    id
                    name
                }
                country
                placementTypes
                advertiserName
                advertiserDomain
            }
        }
    }
`);

export const CreativeList = graphql(`
    query CreativeListV2($input: CreativeListInputV2!) {
        creativeV2 {
            creativeListV2(input: $input) {
                totalCount
                paginationInfo {
                    hasPreviousPage
                    hasNextPage
                    currentPage
                }
                items {
                    id
                    creative
                    firstAppeared
                    lastAppeared
                    status
                    adType {
                        id
                        name
                        canonicalName
                    }
                    categoryList {
                        id
                        name
                        industry {
                            id
                            name
                        }
                    }
                    mediaTypeList {
                        id
                        name
                        canonicalName
                    }
                    mediaOwnerList {
                        id
                        name
                    }
                    publisherList {
                        id
                        name
                    }
                    publicationList {
                        id
                        name
                    }
                    regionList {
                        id
                        name
                    }
                    productList {
                        id
                        name
                        brand {
                            id
                            name
                        }
                    }
                    brandList {
                        id
                        name
                    }
                    industryList {
                        id
                        name
                    }
                    country
                    placementTypes
                    advertiserName
                    advertiserDomain
                }
            }
        }
    }
`);
