import {Flex, Li, Ul} from 'bigdatr-style/layout';
import {Button, Text, Tooltip, useModal, useViewer} from 'bigdatr-style';
import React, {useState, useEffect} from 'react';
import Api from '~/app/EntityApi';
import formatViewerDataToHubspotData from '../../formatViewerDataToHubspotData';
import {useGlobalState} from '~/app/GlobalState';

export default function NzArrived() {
    return (
        <>
            <p>
                Thank you for your patience as we work on bringing you competitor intelligence from
                New Zealand&apos;s digital market! Your team can now be the first to access
                Bigdatr&apos;s coverage across Digital Display, Social and Video channels to:
            </p>

            <Ul>
                <Li>
                    Identify trends and gaps in digital messaging strategies across any industry in
                    New Zealand.
                </Li>
                <Li>
                    Analyse advertiser&apos;s creative direction tailored for local
                    audiences-everyday Kiwis.
                </Li>
                <Li>
                    Compare and report on competitor&apos;s product offerings, pricing and media
                    placements across 3 digital channels.
                </Li>
                <Li>
                    Set up notifications using Ad Content Search to monitor key words, advertiser
                    names, advertising offers and messaging.
                </Li>
            </Ul>

            <Text textStyle="strong">
                Ready to get started? Activate your 5-day access below and we&apos;ll start setting
                it up for you.
            </Text>

            <Flex>
                <RequestNzTrialButton />
            </Flex>
        </>
    );
}

export function RequestNzTrialButton(props: {onSubmit?: () => void}) {
    const addTrial = Api.team.teamAddNzTrial.useRequest();
    const modal = useModal();
    const viewer = useViewer();
    const {setShowNzTopTeaser} = useGlobalState().banners;
    const nzActive = viewer.currentTeam.hasNzCreative;
    // only teams which do not have a NZ product in the DB can give themselves a trial. a product in
    // the DB would exist if they either gave themselves a trial before, or someone from bigdatr
    // gave them NZ access before
    const canRequestTrial = viewer.currentTeam.requestedNzTrial === false;

    const [canTrialNz, setCanRequestTrialNz] = useState(!nzActive && canRequestTrial);

    const tooltipContent = nzActive
        ? 'You already have access to New Zealand data'
        : !canRequestTrial
        ? 'Your team already requested a New Zealand trial'
        : null;

    useEffect(() => {
        if (addTrial.isError) {
            let message =
                'Something went wrong. Please try again, or if the issue persists please reach out.';
            // show a nicer message if we know what went wrong
            if (addTrial.requestError.name === 'NZ_ALREADY_ACCESSED') {
                if (nzActive)
                    message = 'Your team already has New Zealand access. Please refresh your page.';
                else
                    message =
                        'Your team already had a New Zealand trial. Please reach out to discuss your options.';
            }
            modal.message({title: 'Oops', description: message});
        }
    }, [addTrial.isError]);

    useEffect(() => {
        if (addTrial.isSuccess) {
            setShowNzTopTeaser(false);
            props.onSubmit?.();
            modal.message({
                maxWidth: '26rem',
                title: 'Hold tight',
                description:
                    "Please give us a moment while we set up your New Zealand account. We'll email you when it's ready!"
            });
        }
    }, [addTrial.isSuccess]);

    return (
        <Tooltip content={tooltipContent}>
            <Button
                disabled={!canTrialNz}
                loading={addTrial.isFetching || addTrial.isRefetching}
                onClick={() => {
                    setCanRequestTrialNz(false);
                    const data = formatViewerDataToHubspotData(viewer);
                    const formFields = Object.entries(data).map(([fieldName, fieldValue]) => {
                        return {
                            name: fieldName,
                            value:
                                typeof fieldValue === 'string'
                                    ? fieldValue
                                    : JSON.stringify(fieldValue)
                        };
                    });

                    addTrial.request({formFields});
                }}
            >
                Unlock NZ data
            </Button>
        </Tooltip>
    );
}
