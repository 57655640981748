import {graphql} from '~/graphql-react-query/';

export const FilterQuery = graphql(`
    query FilterQuery(
        $input: FilterInputV2!
        $filter: FilterForFilteringFiltersV2
        $options: FilterOptions
    ) {
        filterV2 {
            filters(input: $input, filter: $filter, options: $options) {
                column
                pageInfo {
                    currentPage
                    hasNextPage
                    hasPreviousPage
                }
                items {
                    adType {
                        id
                        name
                        canonicalName
                    }
                    advertiserDomain {
                        id
                        name
                    }
                    advertiserName {
                        id
                        name
                    }
                    brand {
                        id
                        name
                        industries {
                            id
                            name
                        }
                        hasAccess
                    }
                    industry {
                        id
                        name
                        hasAccess
                    }
                    category {
                        id
                        name
                        industry {
                            id
                            name
                        }
                        hasAccess
                    }
                    campaign {
                        id
                        name
                    }
                    publication {
                        id
                        name
                    }
                    publisher {
                        id
                        name
                    }
                    region {
                        id
                        name
                    }
                    product {
                        id
                        name
                        brand {
                            id
                            name
                        }
                        hasAccess
                    }
                    mediaOwner {
                        id
                        name
                    }
                    mediaType {
                        id
                        name
                        canonicalName
                    }
                    changeset {
                        id
                        name
                        status
                    }
                    segment {
                        id
                        name
                        color
                        emailAlert
                        teamId
                        createdAt
                        updatedAt
                        sharing {
                            status
                            usersWithAccess {
                                id
                                name
                                email
                            }
                        }
                        author {
                            id
                            name
                            email
                        }
                    }
                    location {
                        id
                        name
                    }
                    status {
                        id
                        name
                    }
                    placementType {
                        id
                        name
                    }
                }
            }
        }
    }
`);

export const GetEntitiesByIdQuery = graphql(`
    query GetEntitiesById($input: GetEntitiesByIdInput!) {
        filterV2 {
            getEntitiesById(input: $input) {
                adType {
                    id
                    name
                    canonicalName
                }
                brand {
                    id
                    name
                    industries {
                        id
                        name
                    }
                }
                industry {
                    id
                    name
                }
                category {
                    id
                    name
                    industry {
                        id
                        name
                    }
                }
                campaign {
                    id
                    name
                }
                publisher {
                    id
                    name
                }
                publication {
                    id
                    name
                }
                region {
                    id
                    name
                }
                product {
                    id
                    name
                    brand {
                        id
                        name
                    }
                }
                mediaOwner {
                    id
                    name
                }
                mediaType {
                    id
                    name
                    canonicalName
                }
                changeset {
                    id
                    name
                    status
                }
                segment {
                    id
                    name
                    color
                    emailAlert
                    teamId
                    createdAt
                    updatedAt
                    sharing {
                        status
                        usersWithAccess {
                            id
                            name
                            email
                        }
                    }
                    author {
                        id
                        name
                        email
                    }
                }
            }
        }
    }
`);
