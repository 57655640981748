import {graphql} from '~/graphql-react-query/';

export const TeamIncrementDownloadCount = graphql(`
    mutation TeamIncrementDownloadCountMutation($teamId: UUID!) {
        team {
            teamIncrementDownloadCount(mutation: {id: $teamId}) {
                id
                metadata
                updatedAt
            }
        }
    }
`);
