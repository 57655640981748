import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useViewerUnsafe, Viewer, ViewerTeam} from 'bigdatr-style';
import useClientMainAuthorizedFetch from '../clientMainAuthorisedFetch';
import {TeamIncrementDownloadCount} from './graphqlQueries';

export function useIncrementTeamDownloadCount() {
    const viewer = useViewerUnsafe();
    const request = useClientMainAuthorizedFetch();
    const queryClient = useQueryClient();
    const currentTeam = viewer?.currentTeam;

    return useMutation({
        mutationFn: async () => {
            if (!currentTeam) {
                throw new Error(
                    'Cannot increment download count if the viewer doesnt have a current team'
                );
            }
            const response = await request(TeamIncrementDownloadCount, {teamId: currentTeam.id});
            const data = response.team?.teamIncrementDownloadCount;
            if (!data) throw new Error('Team not returned after incrementing download count');

            const {metadata} = data;

            // @TODO fix api to set a new date once the mutation is called.
            // at the moment it returns an updatedAt field, however it does not set a new date
            const updatedAt = new Date().toISOString();
            return new ViewerTeam({
                ...currentTeam,
                updatedAt,
                downloadCount: metadata?.downloadCount
            });
        },
        onSuccess(updatedTeam) {
            if (!viewer) return;
            // update viewer in cache
            queryClient.setQueryData(
                ['viewer', updatedTeam.id],
                new Viewer({
                    ...viewer,
                    teams: viewer.teams.map((oldTeam) => {
                        if (oldTeam.id === updatedTeam.id) {
                            return new ViewerTeam({
                                ...oldTeam,
                                updatedAt: updatedTeam.updatedAt,
                                downloadCount: updatedTeam.downloadCount
                            });
                        }
                        return oldTeam;
                    })
                })
            );
        }
    });
}
