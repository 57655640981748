import {Checkbox, Clickable, Portal, Select, styled, Tooltip} from 'bigdatr-style';
import {useAnalytics, useViewer, Text} from 'bigdatr-style';
import {Box, Flex, Grid} from 'bigdatr-style/layout';
import React, {useState} from 'react';
import useFeatureFlags from '~/util/useFeatureFlags';
import {useCountry} from './CountryContext';
import {
    useFloating,
    useInteractions,
    useClick,
    useDismiss,
    autoUpdate,
    offset,
    shift,
    flip
} from '@floating-ui/react';
import {ChevronSmallDown, LockOn} from 'react-swm-icon-pack';
import IconAU from './flags/IconAU';
import IconNZ from './flags/IconNZ';
import IconCA from './flags/IconCA';
import IconGB from './flags/IconGB';
import IconUS from './flags/IconUS';

enum FutureCountries {
    CA = 'Canada',
    GB = 'United Kingdom',
    US = 'United States'
}

export enum SupportedCountry {
    AU = 'Australia',
    NZ = 'New Zealand'
}
export const AU = SupportedCountry.AU;
export const NZ = SupportedCountry.NZ;

const countryIcons = {
    Australia: <IconAU />,
    'New Zealand': <IconNZ />,
    Canada: <IconCA />,
    'United Kingdom': <IconGB />,
    'United States': <IconUS />
};
export const countryLabels = {
    AU: 'Australia',
    NZ: 'New Zealand'
};

export default function CountrySwitch(props: {
    /** @deprecated */
    showText?: boolean;
}) {
    const {country, setCountry} = useCountry();
    const {navigationV2} = useFeatureFlags();
    const {currentTeam} = useViewer();

    const [open, setOpen] = useState(false);
    const {refs, floatingStyles, context} = useFloating({
        placement: 'bottom-end',
        open,
        onOpenChange: setOpen,
        whileElementsMounted: autoUpdate,

        // order of middleware functions is important
        middleware: [
            offset(8),
            // keep floater in view if a horizontal scroll would make it offscreen
            shift(),
            // change floater placement to top of reference node if it would be offscreen when scrolling
            flip()
        ]
    });

    const click = useClick(context);
    const dismiss = useDismiss(context);

    const {getFloatingProps, getReferenceProps} = useInteractions([click, dismiss]);

    if (!navigationV2 && currentTeam.hasMoreThanOneCountry) {
        const label = (input: {option: SupportedCountry; showText: boolean}) => {
            const {showText} = input;
            switch (input.option) {
                case AU:
                    return (
                        <Flex gap={2} alignItems="center">
                            <IconAU />
                            {showText && countryLabels.AU}
                        </Flex>
                    );
                case NZ:
                    return (
                        <Flex gap={2} alignItems="center">
                            <IconNZ />
                            {showText && countryLabels.NZ}
                        </Flex>
                    );
            }
        };
        return (
            <Select
                transparent
                value={country}
                onChange={setCountry}
                options={[AU, NZ]}
                controlLabel={(option) =>
                    option && label({option, showText: Boolean(props.showText)})
                }
                label={(option) => option && label({option, showText: true})}
                hideCaret
            />
        );
    }

    const isAusAvailable = currentTeam.hasAusCreative || currentTeam.hasAusMediaValue;
    const isNzAvailable = currentTeam.hasNzCreative;

    return (
        <>
            <Clickable
                ref={refs.setReference}
                {...getReferenceProps()}
                data-testid="country-switcher"
            >
                <Flex alignItems="center">
                    {countryIcons[country]}
                    <Box ml={2} mr={1}>
                        {country}
                    </Box>
                    <ChevronSmallDown />
                </Flex>
            </Clickable>

            {open && (
                <Portal>
                    <Floater
                        ref={refs.setFloating}
                        {...getFloatingProps()}
                        style={{...floatingStyles}}
                        p={3}
                    >
                        <CountryChoice
                            checked={country === AU}
                            onChange={() => {
                                setOpen(false);
                                setCountry(AU);
                            }}
                            locked={!isAusAvailable}
                            country={AU}
                        />

                        <CountryChoice
                            checked={country === NZ}
                            onChange={() => {
                                setOpen(false);
                                setCountry(NZ);
                            }}
                            locked={!isNzAvailable}
                            country={NZ}
                        />
                        <CountryChoice locked={true} country={FutureCountries.CA} />
                        <CountryChoice locked={true} country={FutureCountries.GB} />
                        <CountryChoice locked={true} country={FutureCountries.US} />
                    </Floater>
                </Portal>
            )}
        </>
    );
}

const Floater = styled(Box)`
    ${(props) => {
        const {borderRadiuses, colors, boxShadows} = props.theme;
        return `
            width: max-content;
            border: 1px solid ${colors.outline};
            border-radius: ${borderRadiuses.round};
            background-color: ${colors.background};
            box-shadow: ${boxShadows.popup};
            display: flex;
            flex-direction: column;
            gap: 1rem;
        `;
    }}
`;

function CountryChoice({
    country,
    checked,
    locked,
    onChange
}: {
    country: SupportedCountry | FutureCountries;
    checked?: boolean;
    onChange?: () => void;
    locked?: boolean;
}) {
    const analytics = useAnalytics();
    return (
        <Tooltip
            maxWidth="max-content"
            content={locked ? 'To access this feature, contact us.' : null}
        >
            <Grid
                data-testid="country-switcher-option"
                as="label"
                cursor="pointer"
                gridTemplateColumns="1.75rem auto"
                alignItems="center"
                gap={3}
                onClick={locked ? () => analytics.chat() : undefined}
            >
                {countryIcons[country]}
                {locked ? (
                    <Flex alignItems="center" justifyContent="space-between" gap={2}>
                        <Text textStyle="muted">{country}</Text>
                        <Grid
                            placeItems="center"
                            borderWidth="1px"
                            borderStyle="solid"
                            borderColor="background3"
                            size="1.25rem"
                            borderRadius="10rem"
                        >
                            <LockOn size="0.75rem" data-testid="country-lock-icon" />
                        </Grid>
                    </Flex>
                ) : (
                    <Checkbox
                        wrappingElement="div"
                        value={checked}
                        onChange={onChange ?? (() => {})}
                        radio
                        label={country}
                        layoutProps={{
                            fontWeight: 'unset',
                            width: '100%',
                            justifyContent: 'space-between'
                        }}
                    />
                )}
            </Grid>
        </Tooltip>
    );
}
