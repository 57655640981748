import {useLocalStorage, useViewer} from 'bigdatr-style';
import React, {createContext, useContext, useMemo, useState} from 'react';
import useNewsItems from '~/feature/notifications/news/useNewsItems';

type GlobalState = {
    news: {hasUnreadNews: boolean; setLastReadNews: (next: string) => void};
    banners: {
        showNzBottomBanner: boolean | null;
        setShowNzBottomBanner: (next: boolean) => void;
        showNzTopTeaser: boolean | null;
        setShowNzTopTeaser: (next: boolean) => void;
    };
    headerSearch: {
        debouncedSearch: string;
        setDebouncedSearch: (next: string) => void;
    };
};
const GlobalStateContext = createContext<GlobalState | undefined>(undefined);

// @intent - store any global state here that you need to reuse across different pages and component
export function GlobalStateProvider(props: {children: React.ReactNode}) {
    //
    // news page stuff
    const [lastReadNews, setLastReadNews] = useLocalStorage('bd-app-lastReadNotification');
    const newsItems = useNewsItems();

    //
    // banner stuff
    const viewer = useViewer();
    const accessedNzBefore =
        typeof viewer.currentTeam.productMap['New Zealand']?.AdvertisingCreative !== 'undefined';
    const canRequestNzTrial = viewer.currentTeam.requestedNzTrial === false;

    const canSeeNewNzBanners = !accessedNzBefore && canRequestNzTrial;

    const [showNzBottomBanner, setShowNzBottomBanner] = useLocalStorage(
        'bd-app-showNzLiveAnnouncementBanner',
        canSeeNewNzBanners
    );

    const showNewTeaserBanner = Boolean(showNzBottomBanner === false && canSeeNewNzBanners);

    const [showNzTopTeaser, setShowNzTopTeaser] = useState(showNewTeaserBanner);

    const [debouncedSearch, setDebouncedSearch] = useState('');

    const data: GlobalState = useMemo(() => {
        let hasUnreadNews = true;
        if (lastReadNews != null) {
            hasUnreadNews = new Date(lastReadNews) < newsItems[0].date;
        }

        return {
            news: {
                hasUnreadNews,
                setLastReadNews: (next: string) => {
                    setLastReadNews(next);
                }
            },
            banners: {
                showNzBottomBanner,
                setShowNzBottomBanner,
                showNzTopTeaser,
                setShowNzTopTeaser
            },
            headerSearch: {
                debouncedSearch,
                setDebouncedSearch
            }
        };
    }, [
        lastReadNews,
        setLastReadNews,
        newsItems.map((n) => n.date).join(),
        showNzBottomBanner,
        setShowNzBottomBanner,
        showNzTopTeaser,
        setShowNzTopTeaser,
        debouncedSearch
    ]);

    return <GlobalStateContext.Provider value={data}>{props.children}</GlobalStateContext.Provider>;
}

export function useGlobalState() {
    const globalState = useContext(GlobalStateContext);
    if (!globalState) throw new Error('GlobalStateContext is not yet initialized');
    return globalState;
}
