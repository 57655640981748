import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import React from 'react';
import useFeatureFlags from '~/util/useFeatureFlags';

const queryClient = new QueryClient({
    defaultOptions: {
        // changing the stale time to infinity means that react query will not automatically refetch
        // data on subsequent mounts, window focuses or network reconnects.
        //
        // i'm doing this because our data doesn't need to have real-time updates or frequent
        // refreshes. the app hasn't had automatic refetches for 7 years and it didn't seem to be a
        // problem.
        queries: {staleTime: Infinity}
    }
});

export default function ReactQueryProvider(props: {children: React.ReactNode}) {
    const {navigationV2} = useFeatureFlags();
    return (
        <QueryClientProvider client={queryClient}>
            {props.children}
            <ReactQueryDevtools
                initialIsOpen={false}
                buttonPosition={navigationV2 ? 'bottom-left' : 'top-right'}
            />
        </QueryClientProvider>
    );
}
