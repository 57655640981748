import React from 'react';

export default function IconCA() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="21"
            fill="none"
            viewBox="0 0 32 21"
        >
            <path
                fill="#F5F5F5"
                d="M31.448 20.965H.552A.552.552 0 0 1 0 20.413V.552C0 .247.247 0 .552 0h30.896c.305 0 .552.247.552.552v19.861a.552.552 0 0 1-.552.552Z"
            />
            <path fill="#F5F5F5" d="M24.828 0H7.173v20.966h17.655V0Z" />
            <path
                fill="#FF4B55"
                d="M.552 0A.552.552 0 0 0 0 .552v19.862c0 .304.247.551.552.551h6.62V0H.552ZM31.448 0h-6.62v20.965h6.62a.552.552 0 0 0 .552-.551V.552A.552.552 0 0 0 31.448 0ZM19.348 12.905l3.274-1.87-.751-.376a.552.552 0 0 1-.3-.567l.244-1.832-1.386.403a.552.552 0 0 1-.693-.41l-.132-.6-1.302 1.523c-.18.21-.524.05-.477-.224l.556-3.232-.851.233a.551.551 0 0 1-.632-.273L16.002 4v-.002V4L16 3.998v.003l-.896 1.68a.552.552 0 0 1-.632.272l-.851-.233.556 3.232c.047.273-.297.435-.477.224l-1.302-1.524-.133.6a.552.552 0 0 1-.692.411l-1.386-.403.244 1.832a.552.552 0 0 1-.3.567l-.751.375 3.274 1.871c.339.194.487.605.35.97l-.28.75 2.806-.24a.26.26 0 0 1 .283.267l-.089 3.003h.552l-.088-3.003a.26.26 0 0 1 .283-.267l2.806.24-.28-.75a.797.797 0 0 1 .351-.97Z"
            />
        </svg>
    );
}
